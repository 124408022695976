import _ from 'lodash';
import 'regenerator-runtime/runtime';
import { call, put, spawn, takeEvery, takeLeading, select } from 'redux-saga/effects';
import * as actions from '../actions';
import {
    appMetric,
    CREATE_TODO_RESPONSE,
    DELETE_TODO_RESPONSE, errorAlert, FETCH_SORT_ORDER_RESPONSE,
    FETCH_TODO_RESPONSE,
    FETCH_TODOS_RESPONSE,
    loading, SAVE_SORT_ORDER_RESPONSE,
    successAlert,
    UPDATE_TODO_RESPONSE
} from '../actions';
import { appMetricSaga } from './metricsSaga';
import api from '../services/api';
import { errorWrap } from './sagaUtils';
import { completeTodo, decrementTodo, electFailTodo, incrementTodo } from '../utils/todoUtils';

const ROOT_URL = '';

export function* watchFetchTodos() {
    yield takeLeading(actions.FETCH_TODOS, errorWrap(fetchTodosSaga));
}

export function* watchFetchTodo() {
    yield takeLeading(actions.FETCH_TODO, errorWrap(fetchTodoSaga));
}

export function* watchCreateTodo() {
    yield takeEvery(actions.CREATE_TODO, errorWrap(createTodoSaga));
}

export function* watchUpdateTodo() {
    yield takeEvery(actions.UPDATE_TODO, errorWrap(updateTodoSaga));
}

export function* watchDeleteTodo() {
    yield takeEvery(actions.DELETE_TODO, errorWrap(deleteTodoSaga));
}

export function* watchCompleteTodo() {
    yield takeEvery(actions.COMPLETE_TODO, errorWrap(completeTodoSaga));
}

export function* watchElectFailTodo() {
    yield takeEvery(actions.ELECT_FAIL_TODO, errorWrap(electFailTodoSaga));
}

export function* watchIncrementCountTodo() {
    yield takeEvery(actions.INCREMENT_COUNT_TODO, errorWrap(incrementCountTodoSaga));
}

export function* watchDecrementCountTodo() {
    yield takeEvery(actions.DECREMENT_COUNT_TODO, errorWrap(decrementCountTodoSaga));
}

export function* watchSetCountTodo() {
    yield takeEvery(actions.SET_COUNT_TODO, errorWrap(setCountTodoSaga));
}

export function* watchSetGoalProgressTodo() {
    yield takeEvery(actions.SET_GOAL_PROGRESS_TODO, errorWrap(setGoalProgressTodoSaga));
}

export function* watchFetchSortOrder() {
    yield takeLeading(actions.FETCH_SORT_ORDER, errorWrap(fetchSortOrderSaga));
}

export function* watchSaveSortOrder() {
    yield takeEvery(actions.SAVE_SORT_ORDER, errorWrap(saveSortOrderSaga));
}

export function* fetchTodosSaga() {
    yield put(loading(true));
    yield spawn(appMetricSaga, appMetric('API: Fetch Todos'));
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = yield call(api.get, `${ ROOT_URL }/hopto/todo` + '?timezone=' + encodeURIComponent(userTimezone));
    const payload = response ? response.data : {};
    yield put({ type: FETCH_TODOS_RESPONSE, payload });
}

export function* fetchTodoSaga(action) {
    yield put(loading(true));
    yield put(appMetric('API: Fetch Todo'));
    const response = yield call(api.get, `${ ROOT_URL }/hopto/todo/${ action.id }`);
    const payload = response ? response.data : {};
    yield put({ type: FETCH_TODO_RESPONSE, payload });
}

export function* createTodoSaga(action) {
    yield put(appMetric('API: Create Todo'));
    const response = yield call(api.post, `${ ROOT_URL }/hopto/todo`, action.values);
    const payload = response ? response.data : {};
    yield put({ type: CREATE_TODO_RESPONSE, payload });

    const preferences = yield select(state => state.user.preferences);
    if (!preferences?.newItemSort || preferences.newItemSort.toLowerCase() === 'top') {
        let sortOrder = yield select(state => state.todos.sortOrder);
        try {
            const newSortOrder = sortOrder?.order
                ? { ...sortOrder, order: [ payload.id, ...sortOrder.order ] }
                : { order: [payload.id] };
            yield put({ type: SAVE_SORT_ORDER_RESPONSE, payload: newSortOrder});
            yield call(api.put, `${ ROOT_URL }/hopto/todo/sort-order`, newSortOrder);
        } catch (e) {
            yield put({ type: SAVE_SORT_ORDER_RESPONSE, payload: sortOrder});
        }
    }

    if (action.callback) {
        action.callback();
    }

    yield put(successAlert('Todo successfully saved!'));
}

export function* updateTodoSaga(action) {
    yield put(appMetric('API: Update Todo'));
    const response = yield call(api.post, `${ ROOT_URL }/hopto/todo`, action.values);
    const payload = response ? response.data : {};
    yield put({ type: UPDATE_TODO_RESPONSE, payload });

    if (action.callback) {
        action.callback();
    }

    yield put(successAlert('Todo successfully saved!'));
}

export function* deleteTodoSaga(action) {
    yield put(appMetric('API: Delete Todo'));
    try {
        yield put({ type: DELETE_TODO_RESPONSE, payload: action.todo.id });
        yield call(api.delete, `${ ROOT_URL }/hopto/todo/${ action.todo.id }`);
    } catch (e) {
        yield put({ type: CREATE_TODO_RESPONSE, payload: action.todo });
        yield put(errorAlert('Error occurred deleting todo! Please make sure you are online and try again.'));
    }
    if (action.callback) {
        action.callback();
    }
    yield put(successAlert('Todo successfully deleted!'));
}

export function* completeTodoSaga(action) {
    yield put(appMetric('API: Complete Todo'));
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const completedTodo = completeTodo(_.cloneDeep(action.todo));
    try {
        yield put({ type: UPDATE_TODO_RESPONSE, payload: completedTodo });
        yield call(api.put, `${ ROOT_URL }/hopto/todo/complete/${ action.todo.id }` + '?timezone=' + encodeURIComponent(userTimezone));
    } catch (e) {
        yield put({ type: UPDATE_TODO_RESPONSE, payload: action.todo });
        yield put(errorAlert('Error occurred completing todo! Please make sure you are online and try again.'));
    }
}

export function* electFailTodoSaga(action) {
    yield put(appMetric('API: Elect Fail Todo'));
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const failedTodo = electFailTodo(_.cloneDeep(action.todo));
    try {
        yield put({ type: UPDATE_TODO_RESPONSE, payload: failedTodo });
        yield call(api.put, `${ ROOT_URL }/hopto/todo/elect-fail/${ action.todo.id }` + '?timezone=' + encodeURIComponent(userTimezone));
    } catch (e) {
        yield put({ type: UPDATE_TODO_RESPONSE, payload: action.todo });
        yield put(errorAlert('Error occurred failing todo! Please make sure you are online and try again.'));
    }
}

export function* incrementCountTodoSaga(action) {
    yield put(appMetric('API: Increment Count Todo'));
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const incrementedTodo = incrementTodo(_.cloneDeep(action.todo));
    try {
        yield put({ type: UPDATE_TODO_RESPONSE, payload: incrementedTodo });
        yield call(api.put, `${ ROOT_URL }/hopto/todo/increment-count/${ action.todo.id }` + '?timezone=' + encodeURIComponent(userTimezone));
    } catch (e) {
        yield put({ type: UPDATE_TODO_RESPONSE, payload: action.todo });
        yield put(errorAlert('Error occurred incrementing todo! Please make sure you are online and try again.'));
    }
}

export function* decrementCountTodoSaga(action) {
    yield put(appMetric('API: Decrement Count Todo'));
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const decrementedTodo = decrementTodo(_.cloneDeep(action.todo));
    try {
        yield put({ type: UPDATE_TODO_RESPONSE, payload: decrementedTodo });
        yield call(api.put, `${ ROOT_URL }/hopto/todo/decrement-count/${ action.todo.id }` + '?timezone=' + encodeURIComponent(userTimezone));
    } catch (e) {
        yield put({ type: UPDATE_TODO_RESPONSE, payload: action.todo });
        yield put(errorAlert('Error occurred decrementing todo! Please make sure you are online and try again.'));
    }
}

export function* setCountTodoSaga(action) {
    yield put(appMetric('API: Set Count Todo'));
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = yield call(api.put, `${ ROOT_URL }/hopto/todo/set-count/${ action.id }/${ action.count }` + '?timezone=' + encodeURIComponent(userTimezone));
    const payload = response ? response.data : {};
    yield put({ type: UPDATE_TODO_RESPONSE, payload });
    yield put(successAlert('Todo count successfully set!'));
}

export function* setGoalProgressTodoSaga(action) {
    yield put(appMetric('API: Set Goal Progress Todo'));
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = yield call(api.put, `${ ROOT_URL }/hopto/todo/set-goal-progress/${ action.id }/${ action.count }` + '?timezone=' + encodeURIComponent(userTimezone));
    const payload = response ? response.data : {};
    yield put({ type: UPDATE_TODO_RESPONSE, payload });
    yield put(successAlert('Todo goal progress successfully set!'));
}

export function* fetchSortOrderSaga() {
    yield put(loading(true));
    yield spawn(appMetricSaga, appMetric('API: Fetch Sort Order'));
    const response = yield call(api.get, `${ ROOT_URL }/hopto/todo/sort-order`);
    const payload = response ? response.data : {};
    yield put({ type: FETCH_SORT_ORDER_RESPONSE, payload });
}

export function* saveSortOrderSaga(action) {
    yield put(appMetric('API: Save Sort Order'));
    try {
        yield put({ type: SAVE_SORT_ORDER_RESPONSE, payload: action.values});
        yield call(api.put, `${ ROOT_URL }/hopto/todo/sort-order`, action.values);
    } catch (e) {
        // No undo for this - would require us to send previous sort order
        yield put(errorAlert('Error occurred while saving sort order! Please refresh and make sure you are online.'));
    }
}
