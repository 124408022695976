import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Form } from 'semantic-ui-react';
import 'react-datepicker/dist/react-datepicker.css';
import { getChartColor } from '../ChartUtils';

function GoalInsights() {

    const [activeGoal, setActiveGoal] = useState();
    const goals = useSelector((state) => state.todos.list ? state.todos.list.filter(it => it.trackingType === 'number') : null);

    useEffect(() => {
        if (goalOptions().length > 0) {
            setActiveGoal(goalOptions()[0]['value']);
        }
    }, []);

    const goalOptions = () => {
        const options = [];
        goals.forEach(it => options.push({key: it.id, text: it.description, value: it.description }));
        return options;
    };

    const handleChangeGoal = (e, { value }) => setActiveGoal( value );

    const goalsData = () => {
        const gData = [];

        goals.forEach(goal => {
            if (goal.goalOn && goal.description === activeGoal) {
                for (const [key, value] of Object.entries(goal.goalOn)) {
                    const obj = {name: key };
                    obj[goal.description] = value;
                    obj['Goal'] = goal.countGoal;
                    gData.push(obj);
                }
            }
        });

        return gData;
    };

    const goalLines = () => {
        const goalLines = [];
        goals
            .filter(goal => goal.description === activeGoal)
            .forEach((goal, index) => {
                goalLines.push(<Line key={'lineKey' + index} type="monotone" dataKey={goal.description} stroke={getChartColor(index)} />);
                goalLines.push(<Line key={'lineGoalKey' + index} type="monotone" dataKey={'Goal'} stroke={getChartColor(index + 1)} />);
            });
        return goalLines;
    };

    return (
        <div>

            { goals && goals.length &&
                    <>
                        <Form>
                            <Form.Select
                                fluid
                                options={goalOptions()}
                                defaultValue={goalOptions()[0]['value']}
                                placeholder='Goal'
                                onChange={handleChangeGoal}
                            />
                        </Form>
                        <br />

                        <div style={ { 'width': '100%', 'height': '500px', 'backgroundColor': '#ffffff' } }>
                            <ResponsiveContainer width="100%" height="100%">
                                <LineChart
                                    width={ 500 }
                                    height={ 300 }
                                    data={ goalsData() }
                                    margin={ {
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    } }
                                >
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <XAxis dataKey="name"/>
                                    <YAxis/>
                                    <Tooltip/>
                                    <Legend/>
                                    { goalLines() }
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </>
            }

        </div>
    );
}

export default GoalInsights;
