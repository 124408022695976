import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';
import { register as registerUser } from '../../actions/authActions';
import { useForm } from 'react-hook-form';
import PageWrapper from '../wrapper/PageWrapper';
import Privacy from '../policy/Privacy';
import Terms from '../policy/Terms';

const Register = () => {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [policyOpen, setPolicyOpen] = useState(false);
    const [termsOpen, setTermsOpen] = useState(false);

    const handleRegister = (data) => {
        dispatch(registerUser(data.username, data.email, data.password, () => {
            navigate('/');
        }));
    };

    return (
        <PageWrapper>
            <div className="col-md-12">
                <div className="card card-container">
                    <Form onSubmit={ handleSubmit(handleRegister) }>
                        <Form.Field>
                            <label htmlFor="username">Username</label>
                            <input placeholder="Username" { ...register('username', { required: true }) } />
                            { errors.username &&
                                <div className="ui pointing above prompt label" role="alert" aria-atomic="true">Please enter a username</div>
                            }
                        </Form.Field>

                        <Form.Field>
                            <label htmlFor="email">Email</label>
                            <input placeholder="Email" { ...register('email', { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i }) } />
                            { errors.email &&
                                <div className="ui pointing above prompt label" role="alert" aria-atomic="true">Please enter a valid email address</div>
                            }
                        </Form.Field>

                        <Form.Field>
                            <label htmlFor="password">Password</label>
                            <input type="password" placeholder="Password" { ...register('password', { required: true }) } />
                            { errors.password &&
                                <div className="ui pointing above prompt label" role="alert" aria-atomic="true">Please enter a password</div>
                            }
                        </Form.Field>

                        <Button type="button"
                            onClick={ (e) => {
                                e.preventDefault();
                                navigate('/');
                            } }>
                            Cancel
                        </Button>

                        <Button className="primary" type="submit">Register</Button>

                        <Link style={{float: 'right'}} onClick={(e) => {e.preventDefault(); setPolicyOpen(!policyOpen);}} ><strong>Privacy Policy</strong></Link>
                        <Link style={{float: 'right', marginRight: '1rem'}} onClick={(e) => {e.preventDefault(); setTermsOpen(!policyOpen);}} ><strong>Terms of Use</strong></Link>
                    </Form>
                </div>
            </div>

            <Modal
                closeicon
                open={policyOpen}
                onClose={() => setPolicyOpen(false)}
            >
                <Modal.Header>Privacy Policy</Modal.Header>
                <Modal.Content image scrolling>
                    <Privacy />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setPolicyOpen(false)} primary>
                        OK <Icon name='chevron right' />
                    </Button>
                </Modal.Actions>
            </Modal>

            <Modal
                closeicon
                open={termsOpen}
                onClose={() => setTermsOpen(false)}
            >
                <Modal.Header>Terms of Use</Modal.Header>
                <Modal.Content image scrolling>
                    <Terms />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setTermsOpen(false)} primary>
                        OK <Icon name='chevron right' />
                    </Button>
                </Modal.Actions>
            </Modal>

        </PageWrapper>
    );
};

export default Register;
