import React, { useEffect } from 'react';
import Alerts from '../Alerts';
import AuthPageWrapper from '../wrapper/AuthPageWrapper';
import TodosTopPriority from './TodosTopPriority';
import HabitsDashboard from './HabitsDashboard';
import { useDispatch } from 'react-redux';
import { fetchSortOrder, fetchTodos } from '../../actions';
import TasksDueDashboard from './TasksDueDashboard';

function Dashboard() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchSortOrder());
        dispatch(fetchTodos());
        //this.props.appMetric("PAGE: Todos")
    }, [dispatch]);

    return (
        <AuthPageWrapper>
            <div>
                <Alerts/>
                <TasksDueDashboard/>
                <TodosTopPriority/>
                <HabitsDashboard/>
            </div>
        </AuthPageWrapper>
    );
}

export default Dashboard;
