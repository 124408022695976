import React from 'react';
import { Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { saveUserPrefs } from '../../actions';
import PropTypes from 'prop-types';

class ImageCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = { spans: 0 };
        this.imageRef = React.createRef();
    }

    componentDidMount() {
        this.imageRef.current.addEventListener('load', this.setSpans);
    }

    setSpans = () => {
        const height = this.imageRef.current.clientHeight;
        const spans = Math.ceil(height / 10);
        this.setState({ spans });
    };

    setAsBackground = (url) => {
        const user = this.props.user;
        const preferences = this.props.preferences
            ? this.props.preferences
            : {user: user.username};

        this.props.saveUserPrefs({...preferences, backgroundImageUrl: url});
    };

    render() {
        const { description, urls, user } = this.props.image;
        return (
            <div style={{ gridRowEnd: `span ${this.state.spans}`, position: 'relative', minHeight: '100px' }}>
                <img ref={this.imageRef} alt={description} src={urls.regular} />
                <Button
                    size='mini'
                    onClick={() => this.setAsBackground(urls.full)}
                    style={{position: 'absolute', bottom: '30px', right: '30px'}}
                >Set As Background</Button>
                <a href={ user.links.html + '?utm_source=HopSkip&utm_medium=referral&utm_campaign=api-credit' } target="_blank" rel="noreferrer" >
                    <div
                        style={{padding: '0 5px 0 5px', position: 'absolute', bottom: '10px', right: '30px', color: '#ffffff', backgroundColor: 'rgba(255, 255, 255, 0.5)'}}>
                        { user.name }
                    </div>
                </a>
            </div>
        );
    }
}

ImageCard.propTypes = {
    user: PropTypes.object,
    preferences: PropTypes.object,
    image: PropTypes.object,
    saveUserPrefs: PropTypes.func
};

const mapStateToProps = state => {
    return {
        preferences: state.user.preferences,
        user: state.auth.user
    };
};

export default connect(mapStateToProps, { saveUserPrefs })(ImageCard);
