import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTag } from '../../../actions';
import { Dropdown, Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';

function FormTags(props) {
    const [selectedTags, setSelectedTags] = useState(props.tags);
    const user = useSelector((state) => state.auth.user ? state.auth.user : null);
    const tags = useSelector((state) => state.tags.list ? state.tags.list : null);
    const tagOptions = tags.map(tag => {
        return { key: tag.tag, text: tag.tag, value: tag.tag };
    });
    const dispatch = useDispatch();

    const addTag = (event, data) => {
        tagOptions.push({ key: data.value, text: data.value, value: data.value });
        dispatch(createTag({ tag: data.value, user: user.username }, () => {
        }));
    };

    const selectTag = (event, { value }) => {
        setSelectedTags(value);
        props.setSelectedTags(value);
    };

    return (
        <Form.Field>
            <Dropdown
                placeholder='Tags'
                clearable
                multiple
                selection
                search
                allowAdditions
                value={ selectedTags }
                onAddItem={ addTag }
                options={ tagOptions }
                onChange={ selectTag }/>
        </Form.Field>
    );
}

FormTags.propTypes = {
    tags: PropTypes.array,
    setSelectedTags: PropTypes.func
};

export default FormTags;
