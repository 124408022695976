import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTodoByTagInsights } from '../../../actions';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import 'react-datepicker/dist/react-datepicker.css';
import { getChartColor } from '../ChartUtils';
import styles from '../Insights.module.css';

function TodoTagInsights() {

    const tagInsights = useSelector((state) => state.insights.todosByTag);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchTodoByTagInsights());
    }, [dispatch]);


    const graphData = () => {
        const gData = [];

        tagInsights.forEach((insight) => {
            gData.push({ name: insight.tag, value: insight.completed });
        });

        return gData;
    };

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, name }) => {
        const radius = 25 + innerRadius + (outerRadius - innerRadius);
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="rgba(0, 0, 0, 0.87)" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                { name } - {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (

        <>
            { tagInsights &&
                <div className={ styles.pieChartContainer }>
                    <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                            <Pie
                                data={ graphData() }
                                dataKey="value"
                                outerRadius="75%"
                                fill="green"
                                label={renderCustomizedLabel}
                                labelLine={true}
                            >
                                {graphData().map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={getChartColor(index)} />
                                ))}
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            }
        </>
    );
}

export default TodoTagInsights;
