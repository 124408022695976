import _ from 'lodash';
import {
    FETCH_TAGS_RESPONSE,
    CREATE_TAG_RESPONSE,
    RESET_STATE,
    DELETE_TAG_RESPONSE
} from '../actions';

function tagsReducer(state = {list: []}, action) {
    switch (action.type) {
    case FETCH_TAGS_RESPONSE:
        return { ...state, list: sortTags(action.payload) };
    case CREATE_TAG_RESPONSE:
        return {...state, list: sortTags(state.list.concat(action.payload)) };
    case DELETE_TAG_RESPONSE:
        return {...state, list: _.filter(state.list, (tag) => {return tag.id !== action.payload;})};
    case RESET_STATE:
        return {list: []};
    default:
        return state;
    }
}

function sortTags(tags) {
    return tags.sort((a, b) => {
        if (a.tag < b.tag) {
            return -1;
        } else if (a.tag > b.tag) {
            return 1;
        } else {
            return 0;
        }
    });
}

export default tagsReducer;
