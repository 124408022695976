import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { createTodo, deleteTodo, updateTodo } from '../../../actions';
import { Button, Form } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import AuthPageWrapper from '../../wrapper/AuthPageWrapper';
import styles from './Form.module.css';
import FormTags from './FormTags';

function HabitForm() {

    const [selectedTags, setSelectedTags] = useState([]);
    const { register, watch, handleSubmit, reset, formState: { errors } } = useForm();
    const user = useSelector((state) => state?.auth?.user ?? null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const todo = location?.state?.todo;

    useEffect(() => {
        let defaultValues = {};
        defaultValues.user = user.username;
        if (todo) {
            defaultValues.id = todo.id;
            defaultValues.description = todo.description;
            defaultValues.details = todo.details;
            defaultValues.repeatsType = todo.repeatsType;
            defaultValues.tracking = todo.trackingType && todo.trackingType.length > 0;
            defaultValues.trackingType = todo.trackingType;
            defaultValues.countGoal = todo.countGoal;
        }
        reset({ ...defaultValues });
    }, [user.username, todo, reset]);

    const onDeleteClick = () => {
        dispatch(deleteTodo(todo, () => {navigate('/todos');}));
    };

    const onSubmit = (data) => {
        data['tags'] = selectedTags;
        if (data['tracking'] === false) {
            data['trackingType'] = null;
        }

        if (todo) {
            data['completedOn'] = todo.completedOn;
            data['countOn'] = todo.countOn;
            dispatch(updateTodo(data, () => {
                navigate('/todos');
            }));
        } else {
            dispatch(createTodo(data, () => {
                navigate('/todos');
            }));
        }
        //alert(JSON.stringify(data, null, 2))
    };

    return (
        <AuthPageWrapper>
            <Form onSubmit={ handleSubmit(onSubmit) }>
                <Form.Field className={ `${ errors.description ? 'error' : '' }` }>
                    <label>Description</label>
                    <input autoFocus placeholder="Description" { ...register('description', { required: true }) } />
                    { errors.description &&
                        <div className="ui pointing above prompt label" role="alert" aria-atomic="true">Please enter your
                            last name</div> }
                </Form.Field>
                <Form.Field>
                    <label>Details</label>
                    <input placeholder="Details" { ...register('details') } />
                </Form.Field>

                <Form.Group inline className={styles.radioGroup}>
                    <label>Repeat</label>
                    <Form.Field>
                        <div className="ui radio checkbox">
                            <input type="radio" value="daily" { ...register('repeatsType', { required: true }) } />
                            <label>Daily</label>
                        </div>
                    </Form.Field>
                    <Form.Field>
                        <div className="ui radio checkbox">
                            <input type="radio" value="weekly" { ...register('repeatsType', { required: true }) } />
                            <label>Weekly</label>
                        </div>
                    </Form.Field>
                    <Form.Field>
                        <div className="ui radio checkbox">
                            <input type="radio" value="monthly" { ...register('repeatsType', { required: true }) } />
                            <label>Monthly</label>
                        </div>
                    </Form.Field>
                    <Form.Field className={ `${ errors.repeatsType ? 'error' : '' }` }>
                        { errors.repeatsType &&
                        <div className="ui left pointing prompt label error" role="alert" aria-atomic="true">Please choose daily, weekly or monthly</div> }
                    </Form.Field>
                </Form.Group>

                <Form.Field className={styles.toggle}>
                    <div className="ui checkbox toggle">
                        <input type="checkbox" id="tracking-id" tabIndex="1" { ...register('tracking') } />
                        <label>Tracking</label>
                    </div>
                </Form.Field>

                { watch('tracking') &&
                    <Form.Group inline className={styles.radioGroup}>
                        <label>Track</label>
                        <Form.Field>
                            <div className="ui radio checkbox">
                                <input type="radio" value="finished" { ...register('trackingType', { required: true }) } />
                                <label>Finished</label>
                            </div>
                        </Form.Field>
                        <Form.Field>
                            <div className="ui radio checkbox">
                                <input type="radio" value="finished_fail" { ...register('trackingType', { required: true }) } />
                                <label>Finished / Failed</label>
                            </div>
                        </Form.Field>
                        <Form.Field>
                            <div className="ui radio checkbox">
                                <input type="radio" value="count" { ...register('trackingType', { required: true }) } />
                                <label>Count</label>
                            </div>
                        </Form.Field>
                        <Form.Field>
                            <div className="ui radio checkbox">
                                <input type="radio" value="count_goal" { ...register('trackingType', { required: true }) } />
                                <label>Count with Goal</label>
                            </div>
                        </Form.Field>
                        <Form.Field className={ `${ errors.trackingType ? 'error' : '' }` }>
                            { errors.trackingType &&
                            <div className="ui left pointing prompt label" role="alert" aria-atomic="true">Please choose a tracking type</div> }
                        </Form.Field>
                    </Form.Group>
                }

                { watch('tracking') && watch('trackingType') === 'count_goal' &&
                    <Form.Field className={ `${ errors.countGoal ? 'error' : '' }` }>
                        <label>Count Goal</label>
                        <input type="number"
                            placeholder="Count Goal" { ...register('countGoal', { required: true }) } />
                        { errors.countGoal &&
                            <div className="ui pointing above prompt label" role="alert" aria-atomic="true">Please enter a
                                count goal</div> }
                    </Form.Field>
                }

                <FormTags tags={todo ? todo.tags : []} setSelectedTags={setSelectedTags}/>

                {todo &&
                    <Button type="button"
                        className="negative"
                        onClick={onDeleteClick}>
                        Delete Todo
                    </Button>
                }

                <Button type="button"
                    className="secondary"
                    onClick={ (e) => {
                        e.preventDefault();
                        navigate('/todos');
                    } }>
                    Cancel
                </Button>
                <Button className="primary" type="submit">Submit</Button>
            </Form>
        </AuthPageWrapper>
    );

}

export default HabitForm;
