import React from 'react';
import {useDroppable} from '@dnd-kit/core';
import PropTypes from 'prop-types';

function TodoDropWrapper(props) {
    const {isOver, setNodeRef} = useDroppable({
        id: 'droppable',
    });

    const style = {
        color: isOver ? 'green' : undefined,
    };

    return (
        <div ref={setNodeRef} style={style}>
            {props.children}
        </div>
    );
}

TodoDropWrapper.propTypes = {
    children: PropTypes.element
};

export default TodoDropWrapper;
