import AuthPageWrapper from '../wrapper/AuthPageWrapper';
import Alerts from '../Alerts';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Form } from 'semantic-ui-react';
import { HexColorPicker, RgbaColorPicker } from 'react-colorful';
import { useDispatch, useSelector } from 'react-redux';
import { saveUserPrefs } from '../../actions';
import { useOnClickOutside } from 'usehooks-ts';

import styles from './Preferences.module.css';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

function Preferences() {

    const rgbaString = (color) => {
        return 'rgba(' + color.r + ',' + color.g + ',' + color.b + ',' + color.a + ')';
    };

    const rgbaObject = (rgbaString) => {
        const rgbaStringNoParens = rgbaString.replace('rgba(', '').replace(')', '');
        const rgbaArray = rgbaStringNoParens.split(',');
        return {
            r: rgbaArray[0],
            g: rgbaArray[1],
            b: rgbaArray[2],
            a: rgbaArray[3]
        };
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { register, handleSubmit, reset } = useForm();

    const user = useSelector(state => state.auth.user ? state.auth.user : null);
    const preferences = useSelector(state => state.user.preferences);

    const [color, setColor] = useState(preferences.cardBackgroundRgba ? rgbaObject(preferences.cardBackgroundRgba) : { r: 255, g: 255, b: 255, a: 1.0 });
    const [colorString, setColorString]  = useState(preferences.cardBackgroundRgba ? preferences.cardBackgroundRgba : 'rgba(255,255,255,1.0)');
    const [titleColor, setTitleColor] = useState(preferences.cardTitleHex ? preferences.cardTitleHex : '#546e7a');

    const [isColorOpen, setIsColorOpen] = React.useState(false);
    const [isTitleColorOpen, setIsTitleColorOpen] = React.useState(false);
    const colorPopover = useRef();
    const titleColorPopover = useRef();
    useOnClickOutside(colorPopover, () => setIsColorOpen(false));
    useOnClickOutside(titleColorPopover, () => setIsTitleColorOpen(false));

    useEffect(() => {
        let defaultValues = {};
        setColorString(rgbaString(color));

        if (preferences) {
            defaultValues.newItemSort = preferences?.newItemSort ?? 'top';
        }
        reset({ ...defaultValues });
    }, [color]);

    const onSavePreferences = (data) => {
        const prefs = {
            ...preferences,
            user: user.username,
            cardTitleHex: titleColor,
            cardBackgroundRgba: colorString,
            newItemSort: data['newItemSort']
        };
        dispatch(saveUserPrefs(prefs, () => {
            navigate('/todos');
        }));
    };

    return (
        <AuthPageWrapper>
            <div>

                <Alerts/>

                <h1>Preferences</h1>

                <Card fluid style={{backgroundColor: `${colorString}`}}>
                    <Card.Content>
                        <Card.Header style={{color: `${titleColor}`}}>
                            Todo Description
                        </Card.Header>

                        <Card.Description>
                            Todo Details
                        </Card.Description>

                        <h3 style={{color: `${titleColor}`}}>Card Color & Opacity</h3>
                        <div className={ styles.picker }>
                            <div
                                className={ styles.swatch }
                                style={{ backgroundColor: color }}
                                onClick={() => setIsColorOpen(true)}
                            />

                            {isColorOpen && (
                                <div className={ styles.popover } ref={colorPopover}>
                                    <RgbaColorPicker color={color} onChange={setColor} />
                                </div>
                            )}
                        </div>

                        <h3 style={{color: `${titleColor}`}}>Card Title Color</h3>
                        <div className={ styles.picker }>
                            <div
                                className={ styles.swatch }
                                style={{ backgroundColor: titleColor }}
                                onClick={() => setIsTitleColorOpen(true)}
                            />

                            {isTitleColorOpen && (
                                <div className={ styles.popover } ref={titleColorPopover}>
                                    <HexColorPicker color={titleColor} onChange={setTitleColor} />
                                </div>
                            )}
                        </div>

                        <Form.Group inline className={styles.radioGroup}>
                            <label>New Item Sort</label>
                            <Form.Field>
                                <div className="ui radio checkbox">
                                    <input type="radio" value="top" { ...register('newItemSort', { required: true }) } />
                                    <label>Top</label>
                                </div>
                            </Form.Field>
                            <Form.Field>
                                <div className="ui radio checkbox">
                                    <input type="radio" value="bottom" { ...register('newItemSort', { required: true }) } />
                                    <label>Bottom</label>
                                </div>
                            </Form.Field>
                        </Form.Group>

                    </Card.Content>

                    <Card.Content extra className='right aligned'>
                        <div>

                            <Button onClick={handleSubmit(data => onSavePreferences(data))}>Save Prefs</Button>

                        </div>
                    </Card.Content>

                </Card>

            </div>

        </AuthPageWrapper>
    );
}

export default Preferences;
