import React, { useEffect, useState } from 'react';
import {Button, Container, Dropdown, Form, Header, Icon, Menu, MenuItem, Modal, Sidebar} from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../actions/authActions';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { fetchUserPrefs, saveFeedback } from '../../actions';
import { useForm } from 'react-hook-form';
import Privacy from '../policy/Privacy';
import PropTypes from 'prop-types';
import Terms from '../policy/Terms';

// eslint-disable-next-line no-unused-vars
import baseStyles from '../style.module.css';
import styles from './Wrapper.module.css';

const AuthPageWrapper = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector(state => state.auth.user ? state.auth.user : null);
    const preferences = useSelector(state => state.user.preferences);

    const [feedbackOpen, setFeedbackOpen] = useState(false);
    const [policyOpen, setPolicyOpen] = useState(false);
    const [termsOpen, setTermsOpen] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm();

    useEffect(() => {
        dispatch(fetchUserPrefs());
        //this.props.appMetric("PAGE: Todos")
    }, [dispatch]);

    const onSubmit = (data) => {
        dispatch(saveFeedback(data, () => {
            setFeedbackOpen(false);
        }));
        //alert(JSON.stringify(data, null, 2))
    };

    if (!user) {
        return <Navigate to="/login"/>;
    }

    const containerStyle = {};
    const bgImageUrl = (preferences && preferences.backgroundImageUrl)
        ? 'url(' + preferences.backgroundImageUrl + ')'
        : 'url(/bg.jpg)';

    // If preferences are loaded! Otherwise default image shows prior to custom!
    if (preferences !== null) {
        containerStyle['backgroundImage'] = bgImageUrl;
    }

    return (
        <div className={ styles.containerStyle } style={ containerStyle }>
            <Container>

                <Sidebar
                    className="mobile only"
                    as={Menu}
                    animation='overlay'
                    icon='labeled'
                    inverted
                    onHide={() => setMobileMenuOpen(false)}
                    vertical
                    visible={mobileMenuOpen}
                    width='thin'
                >
                    <MenuItem as='a' onClick={() => navigate('/todos')} active={window.location.pathname.includes('/todos')}>
                        <Icon name='home' />
                        Home
                    </MenuItem>
                    <MenuItem as='a' onClick={() => navigate('/lists/list')} active={window.location.pathname.includes('/lists')}>
                        <Icon name='gamepad' />
                        Lists
                    </MenuItem>
                    <MenuItem as='a' onClick={() => navigate('/insights')} active={window.location.pathname.includes('/insights')}>
                        <Icon name='camera'/>
                        Insights
                    </MenuItem>
                    <MenuItem as='a' onClick={() => navigate('/dashboard')} active={window.location.pathname.includes('/dashboard')}>
                        <Icon name='camera'/>
                        Dashboard
                    </MenuItem>
                </Sidebar>

                <div className="ui top fixed menu">

                    <h4 className="ui header item mobile only">
                        <Icon name='bars' onClick={() => setMobileMenuOpen(!mobileMenuOpen)}/>
                    </h4>

                    <h3 className="ui header item">HopSkip</h3>

                    {/* eslint-disable-next-line no-restricted-globals */ }
                    <Link to="/todos"
                        className={ `mobile hidden ${ window.location.pathname.includes('/todos') ? 'active' : '' } item` }>Home</Link>
                    {/* eslint-disable-next-line no-restricted-globals */ }
                    <Link to="/lists/list"
                        className={ `mobile hidden ${ window.location.pathname.includes('/lists') ? 'active' : '' } item` }>Lists</Link>
                    {/* eslint-disable-next-line no-restricted-globals */ }
                    <Link to="/insights"
                        className={ `mobile hidden ${ window.location.pathname.includes('/insights') ? 'active' : '' } item` }>Insights</Link>
                    {/* eslint-disable-next-line no-restricted-globals */ }
                    <Link to="/dashboard"
                        className={ `mobile hidden ${ window.location.pathname.includes('/dashboard') ? 'active' : '' } item` }>Dashboard</Link>

                    <div className="right menu">

                        <Dropdown item text={ user.username }>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={ () => {
                                    navigate('/change-password');
                                } }>Change Password
                                </Dropdown.Item>

                                <Dropdown.Item onClick={ () => {
                                    navigate('/tags');
                                } }>Manage Tags
                                </Dropdown.Item>

                                <Dropdown.Item onClick={ () => {
                                    navigate('/background');
                                } }>Change Background
                                </Dropdown.Item>

                                <Dropdown.Item onClick={ () => {
                                    navigate('/preferences');
                                } }>Preferences
                                </Dropdown.Item>

                                <Dropdown.Item onClick={ () => {
                                    setPolicyOpen(!policyOpen);
                                } }>Privacy Policy
                                </Dropdown.Item>

                                <Dropdown.Item onClick={ () => {
                                    setTermsOpen(!termsOpen);
                                } }>Terms of Use
                                </Dropdown.Item>

                                <Dropdown.Item onClick={ () => {
                                    setFeedbackOpen(!feedbackOpen);
                                } }>Feedback
                                </Dropdown.Item>

                                <Dropdown.Item onClick={ (e) => {
                                    e.preventDefault();
                                    dispatch(logout());
                                } }>Logout
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                    </div>
                </div>

                <div style={ { height: '72px' } }/>

                <Modal
                    closeIcon
                    open={feedbackOpen}
                    onClose={() => setFeedbackOpen(false)}
                >
                    <Header icon='sync' content='HopSkip Feedback' />
                    <Modal.Content>
                        <Form onSubmit={ handleSubmit(onSubmit) }>
                            <Form.Field className={ `${ errors.feedback ? 'error' : '' }` }>
                                <label>Feedback</label>
                                <textarea placeholder="What's on your mind..."
                                    { ...register('feedback', { required: true }) }>
                                </textarea>
                                { errors.feedback &&
                                    <div className="ui pointing above prompt label" role="alert" aria-atomic="true">
                                        Please enter your feedback
                                    </div>
                                }
                            </Form.Field>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={() => setFeedbackOpen(false)}>
                            <Icon name='remove' /> Cancel
                        </Button>
                        <Button color='green' onClick={handleSubmit(data => onSubmit(data))}>
                            <Icon name='checkmark' /> Submit
                        </Button>
                    </Modal.Actions>
                </Modal>

                <Modal
                    closeicon
                    open={policyOpen}
                    onClose={() => setPolicyOpen(false)}
                >
                    <Modal.Header>Privacy Policy</Modal.Header>
                    <Modal.Content image scrolling>
                        <Privacy />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => setPolicyOpen(false)} primary>
                            OK <Icon name='chevron right' />
                        </Button>
                    </Modal.Actions>
                </Modal>

                <Modal
                    closeicon
                    open={termsOpen}
                    onClose={() => setTermsOpen(false)}
                >
                    <Modal.Header>Terms of Use</Modal.Header>
                    <Modal.Content image scrolling>
                        <Terms />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => setTermsOpen(false)} primary>
                            OK <Icon name='chevron right' />
                        </Button>
                    </Modal.Actions>
                </Modal>

                { props.children }

            </Container>

        </div>
    );
};

AuthPageWrapper.propTypes = {
    children: PropTypes.element
};

export default AuthPageWrapper;
