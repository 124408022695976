import React, { useState } from 'react';
import Alerts from '../Alerts';
import AuthPageWrapper from '../wrapper/AuthPageWrapper';
import { Menu } from 'semantic-ui-react';
import 'react-datepicker/dist/react-datepicker.css';
import TodoInsights from './insights/TodoInsights';
import HabitInsights from './insights/HabitInsights';
import GoalInsights from './insights/GoalInsights';
import DateDoneInsights from './insights/DateDoneInsights';

function Insights() {

    const [activeItem, setActiveItem] = useState('todos');

    const menuItem = (key, display) => {
        return (
            <Menu.Item
                name={key}
                active={activeItem === key}
                onClick={() => setActiveItem(key)}
            >
                { display }
            </Menu.Item>
        );
    };

    return (
        <AuthPageWrapper>
            <div>

                <Alerts/>

                <Menu>
                    { menuItem('todos', 'Todo Insights')}
                    { menuItem('habits', 'Habit Insights')}
                    { menuItem('goals', 'Goal Insights')}
                    { menuItem('date', 'Completed On Date')}
                </Menu>

                { activeItem === 'date' &&
                    <DateDoneInsights/>
                }

                { activeItem === 'todos' &&
                    <TodoInsights/>
                }

                { activeItem === 'habits' &&
                    <HabitInsights/>
                }

                { activeItem === 'goals' &&
                    <GoalInsights/>
                }

            </div>
        </AuthPageWrapper>
    );
}

export default Insights;
