import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://api.unsplash.com',
    headers: {
        Authorization: 'Client-ID AazX0WNQxN_zcauIRLer7pgAomdL3tiooQTOPdc67bo'
    }
});

export default instance;
