import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { completeRegistration } from '../../actions/authActions';
import PageWrapper from '../wrapper/PageWrapper';
import { actionRequiredAlertClear } from '../../actions';

const CompleteRegistration = () => {

    const { username, code } = useParams();
    const dispatch = useDispatch();
    let navigate = useNavigate();

    useEffect(() => {
        dispatch(actionRequiredAlertClear());
        dispatch(completeRegistration(username, code, () => {
            navigate('/login');
        }));
    }, [username, code, dispatch, navigate]);

    return (
        <PageWrapper>
            <div className="col-md-12">
                <div className="card card-container">
                    <h2>Contacting server to complete registration</h2>
                </div>
            </div>
        </PageWrapper>
    );
};

export default CompleteRegistration;
