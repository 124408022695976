import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSortOrder, fetchTags, fetchTodos, saveSortOrder } from '../../actions';
import { Grid, Header } from 'semantic-ui-react';
import Alerts from '../Alerts';
import Todo from './Todo';
import { CardTypes, getType, isDone, orderedItemsOfType } from '../../utils/todoUtils';
import AuthPageWrapper from '../wrapper/AuthPageWrapper';
import TodosHeader from './TodosHeader';
import {
    closestCenter,
    DndContext,
    KeyboardSensor,
    MouseSensor,
    PointerSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import TodoDropWrapper from './TodoDropWrapper';
import TodoDragWrapper from '../tag/TodoDragWrapper';

import styles from './Todos.module.css';
import { Link } from 'react-router-dom';

function Todos() {

    const map = useSelector((state) => state.todos.map);
    const sortOrder = useSelector((state) => state?.todos?.sortOrder?.order ?? []);
    const todos = useSelector((state) => state.todos.list ? state.todos.list : null);
    useSelector((state) => state.tags.list ? state.tags.list : null);
    const todoTypeFilters = useSelector(state => state.ux.todoTypeFilters);
    const todoTagFilters = useSelector(state => state.ux.todoTagFilters);
    const user = useSelector((state) => state.auth.user ? state.auth.user : null);
    const todoView = useSelector(state => state.ux.todoView);

    const dispatch = useDispatch();

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10, // Enable sort function when dragging 10px here!!!
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const mobileSensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 8,
            },
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 300,
                tolerance: 8,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    useEffect(() => {
        dispatch(fetchSortOrder());
        dispatch(fetchTodos());
        dispatch(fetchTags());
        //this.props.appMetric("PAGE: Todos")
    }, [dispatch]);

    const getSortItemsOfType = (type) => {
        return visibleCardsOfType(type, sortOrder).map(it => it.id);
    };

    const visibleCardsOfType = (type, sortOrder) => {
        return orderedItemsOfType(todos, type, map, sortOrder)
            .filter(item => isTodoTypeVisible(item) &&
                isTodoTagsVisible(item)
            );
    };

    const getOrderedCards = () => {
        const ordered = [];
        Object.values(CardTypes).forEach(type => {
            orderedItemsOfType(todos, type, map, sortOrder)
                .filter(item => !isDone(item))
                .forEach(card => {
                    ordered.push(card);
                });
        });
        return ordered;
    };

    const showCardsOfType = (type) => {
        return visibleCardsOfType(type, sortOrder).length > 0;
    };

    const SectionHeaders = {
        [CardTypes.todo]: 'Todos',
        [CardTypes.habit]: 'Habits',
        [CardTypes.goal]: 'Goals'
    };

    const isTodoTypeVisible = (todo) => {
        return !todoTypeFilters ||
            todoTypeFilters.length === 0 ||
            // Habit
            (todo.repeatsType && todoTypeFilters.includes('habit')) ||
            // Repeating
            (todo.repeatsType && todoTypeFilters.includes(todo.repeatsType.toLowerCase())) ||
            // Todo
            (!todo.repeatsType && todo.trackingType !== 'number' && todoTypeFilters.includes('todo')) ||
            // Goal
            (todo.trackingType === 'number' && todoTypeFilters.includes('goal')) ||
            // Completed todos
            (isDone(todo) && todoTypeFilters.includes('completed'));
    };

    const isTodoTagsVisible = (todo) => {
        if (!todoTagFilters || todoTagFilters.length === 0) return true;
        if (!todo.tags || todo.tags.length === 0) return false;
        for (const tagKey in todo.tags) {
            if (todoTagFilters.includes(todo.tags[tagKey])) return true;
        }
        return false;
    };

    function handleDragEnd(event) {
        const { active, over } = event;
        const activeItem = map[active.id];
        const overItem = map[over.id];

        if (active.id === over.id ||
            getType(activeItem) !== getType(overItem)) {
            return;
        }

        const orderedCards = getOrderedCards();
        const oldIndex = orderedCards.indexOf(map[active.id]);
        const newIndex = orderedCards.indexOf(map[over.id]);
        const orderedIds = arrayMove(orderedCards, oldIndex, newIndex).map(card => card.id);

        const sortOrder = { user: user.username, order: orderedIds };

        dispatch(saveSortOrder(sortOrder));
    }

    const renderCardSectionOfType = (type) => {
        if (!showCardsOfType(type)) return;

        return (
            <React.Fragment key={type}>
                { renderCardSectionForDevice(type, 4, 'computer only')}
                { renderCardSectionForDevice(type, 3, 'tablet only')}
                { renderCardSectionForDevice(type, 1, 'mobile only')}
            </React.Fragment>
        );
    };

    const renderCardSectionForDevice = (type, columns, device) => {
        if (!showCardsOfType(type)) return;

        return (
            <>
                <Grid columns={ columns } className={ device }>
                    { renderCardSectionHeader(type) }
                </Grid>
                <DndContext
                    sensors={ columns === 1 ? mobileSensors : sensors }
                    collisionDetection={ closestCenter }
                    onDragEnd={ handleDragEnd }>
                    <SortableContext
                        items={ getSortItemsOfType(type) }
                    >
                        <Grid columns={ columns } className={ device }>
                            { renderCardOfType(type) }
                        </Grid>
                    </SortableContext>
                </DndContext>
            </>
        );
    };

    const renderVerticalCardSectionOfType = (type) => {
        if (!showCardsOfType(type)) return;

        return (
            <DndContext
                sensors={ sensors }
                collisionDetection={ closestCenter }
                onDragEnd={ handleDragEnd }>
                <SortableContext
                    items={ getSortItemsOfType(type) }
                    strategy={ verticalListSortingStrategy }
                >
                    <Grid.Column>
                        { renderVerticalCardSectionHeader(type) }
                        <TodoDropWrapper id={ type }>
                            { renderVerticalCardOfType(type) }
                        </TodoDropWrapper>
                    </Grid.Column>
                </SortableContext>
            </DndContext>
        );
    };

    const renderCardSectionHeader = (type) => {
        return (
            <Grid.Column>
                <div className={ styles.todosSectionTitle }>
                    <Header as='h3' className={styles.todoSectionHeaderLeft}>
                        { SectionHeaders[type] }
                        {type !== CardTypes.goal &&
                            ' - ' +
                            visibleCardsOfType(type, sortOrder).filter(item => !isDone(item)).length.toString() +
                            '/' +
                            visibleCardsOfType(type, sortOrder).filter(item => isDone(item)).length.toString()
                        }
                    </Header>
                    <Header as='h3' className={styles.todoSectionHeaderRight}>
                        <Link to={'/todos/new-' + type} style={{color: 'rgba(0, 0, 0, .87)'}}>
                            Add New
                        </Link>
                    </Header>
                </div>
            </Grid.Column>);
    };

    const renderCardOfType = (type) => {
        return visibleCardsOfType(type, sortOrder)
            .map((todo) => {
                return (
                    <Grid.Column key={ todo.id }>
                        <TodoDragWrapper key={ todo.id } id={ todo.id }>
                            <Todo todo={ todo }/>
                        </TodoDragWrapper>
                    </Grid.Column>
                );
            });
    };

    const renderVerticalCardSectionHeader = (type) => {
        return (
            <div className={ styles.todosSectionTitleColumns }>
                <Header as='h3' className={styles.todoSectionHeaderLeft}>{ SectionHeaders[type] }</Header>
                {type !== CardTypes.goal &&
                    <Header as='h3' className={styles.todoSectionHeaderRight}>
                        { visibleCardsOfType(type, sortOrder).filter(item => !isDone(item)).length }
                        /
                        { visibleCardsOfType(type, sortOrder).filter(item => isDone(item)).length }
                    </Header>
                }
            </div>);
    };

    const renderVerticalCardOfType = (type) => {
        return visibleCardsOfType(type, sortOrder)
            .map((todo) => {
                return (
                    <TodoDragWrapper key={ todo.id } id={ todo.id }>
                        <span style={{height: '.5rem'}}></span>
                        {/*<span style={ { color: "yellow" } }>{ todo.id }</span>*/}
                        <Todo todo={ todo }/>
                    </TodoDragWrapper>
                );
            });
    };

    return (
        <AuthPageWrapper>
            <div>

                <Alerts/>

                <TodosHeader/>

                {todoView !== 'columns' && todos && sortOrder !== null &&
                    Object.values(CardTypes).map(type => {
                        return renderCardSectionOfType(type);
                    })
                }

                { todoView === 'columns' &&
                    <Grid columns={ 3 } className="mobile hidden">
                        { todos && sortOrder !== null &&
                            Object.values(CardTypes).map(type => {
                                return renderVerticalCardSectionOfType(type);
                            })
                        }
                    </Grid>
                }

                { todoView === 'columns' &&
                    <Grid columns={ 1 } className="mobile only">
                        { todos && sortOrder !== null &&
                            Object.values(CardTypes).map(() => {
                                return renderVerticalCardSectionOfType(CardTypes.todo);
                            })
                        }
                    </Grid>
                }

            </div>
        </AuthPageWrapper>
    );
}

export default Todos;
