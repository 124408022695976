export const FETCH_USER_PREFS = 'FETCH_USER_PREFS';
export const FETCH_USER_PREFS_RESPONSE = 'FETCH_USER_PREFS_RESPONSE';
export const SAVE_USER_PREFS = 'SAVE_USER_PREFS';
export const SAVE_USER_PREFS_RESPONSE = 'SAVE_USER_PREFS_RESPONSE';

export function fetchUserPrefs() {
    return {
        type: FETCH_USER_PREFS
    };
}

export function saveUserPrefs(values, callback) {
    return {
        type: SAVE_USER_PREFS,
        values,
        callback
    };
}
