export const SUCCESS_ALERT = 'SUCCESS_ALERT';
export const SUCCESS_ALERT_SAVE = 'SUCCESS_ALERT_SAVE';
export const SUCCESS_ALERT_CLEAR = 'SUCCESS_ALERT_CLEAR';
export const ERROR_ALERT = 'ERROR_ALERT';
export const ERROR_ALERT_SAVE = 'ERROR_ALERT_SAVE';
export const ERROR_ALERT_CLEAR = 'ERROR_ALERT_CLEAR';
export const ACTION_REQUIRED_ALERT = 'ACTION_REQUIRED_ALERT';
export const ACTION_REQUIRED_ALERT_SAVE = 'ACTION_REQUIRED_ALERT_SAVE';
export const ACTION_REQUIRED_ALERT_CLEAR = 'ACTION_REQUIRED_ALERT_CLEAR';
export const APP_METRIC = 'APP_METRIC';
export const LOADING = 'LOADING';
export const RESET_STATE = 'RESET_STATE';

export function successAlert(message) {
    return {
        type: SUCCESS_ALERT,
        message
    };
}

export function successAlertSave(message) {
    return {
        type: SUCCESS_ALERT_SAVE,
        message
    };
}

export function successAlertClear() {
    return {
        type: SUCCESS_ALERT_CLEAR
    };
}

export function errorAlert(message) {
    return {
        type: ERROR_ALERT,
        message
    };
}

export function errorAlertSave(message) {
    return {
        type: ERROR_ALERT_SAVE,
        message
    };
}

export function errorAlertClear() {
    return {
        type: ERROR_ALERT_CLEAR
    };
}

export function actionRequiredAlert(message) {
    return {
        type: ACTION_REQUIRED_ALERT,
        message
    };
}

export function actionRequiredAlertSave(message) {
    return {
        type: ACTION_REQUIRED_ALERT_SAVE,
        message
    };
}

export function actionRequiredAlertClear() {
    return {
        type: ACTION_REQUIRED_ALERT_CLEAR
    };
}

export function appMetric(metric) {
    return {
        type: APP_METRIC,
        metric
    };
}

export function loading(loading) {
    return {
        type: LOADING,
        loading
    };
}
