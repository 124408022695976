import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { createListItem, deleteListItem } from '../../../actions';
import { Button, Form } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import AuthPageWrapper from '../../wrapper/AuthPageWrapper';

function ListItemForm() {

    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const user = useSelector((state) => state.auth.user ? state.auth.user : null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let defaultValues = {};
        defaultValues.user = user.username;
        if (location.state && location.state.list) {
            defaultValues.listId = location.state.list.id;
            defaultValues.description = location.state.list.template;
        }
        if (location.state && location.state.listItem) {
            const listItem = location.state.listItem;
            defaultValues.id = listItem.id;
            defaultValues.listId = listItem.listId;
            defaultValues.name = listItem.name;
            defaultValues.description = listItem.description;
        }
        reset({ ...defaultValues });
    }, [user.username, location.state, reset]);

    const onDeleteClick = () => {
        dispatch(deleteListItem(location.state.listItem, () => {navigate('/lists/list');}));
    };

    const onSubmit = (data) => {
        dispatch(createListItem(data, () => {
            navigate('/lists/list');
        }));
        //alert(JSON.stringify(data, null, 2))
    };

    return (
        <AuthPageWrapper>
            <Form onSubmit={ handleSubmit(onSubmit) }>
                <Form.Field className={ `${ errors.name ? 'error' : '' }` }>
                    <label htmlFor="name">Name</label>
                    <input placeholder="Name" { ...register('name', { required: true }) } />
                    { errors.name &&
                        <div className="ui pointing above prompt label" role="alert" aria-atomic="true">Please enter a name</div> }
                </Form.Field>

                <Form.Field>
                    <label htmlFor="description">Description</label>
                    <textarea placeholder="Description" { ...register('description', { required: false }) } />
                </Form.Field>

                {location.state && location.state.listItem &&
                    <Button type="button"
                        className="negative"
                        onClick={onDeleteClick}>
                        Delete List Item
                    </Button>
                }

                <Button type="button"
                    className="secondary"
                    onClick={ (e) => {
                        e.preventDefault();
                        navigate('/lists/list');
                    } }>
                    Cancel
                </Button>
                <Button className="primary" type="submit">Submit</Button>
            </Form>
        </AuthPageWrapper>
    );

}

export default ListItemForm;
