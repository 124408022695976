import 'regenerator-runtime/runtime';
import { call, put, spawn, takeEvery, takeLeading } from 'redux-saga/effects';
import * as actions from '../actions';
import {
    appMetric,
    CREATE_LIST_ITEM_RESPONSE,
    CREATE_LIST_RESPONSE,
    DELETE_LIST_ITEM_RESPONSE,
    DELETE_LIST_RESPONSE,
    FETCH_LIST_RESPONSE,
    FETCH_LISTS_RESPONSE,
    loading,
    successAlert,
    UPDATE_LIST_ITEM_RESPONSE,
    UPDATE_LIST_RESPONSE
} from '../actions';
import { appMetricSaga } from './metricsSaga';
import api from '../services/api';
import { errorWrap } from './sagaUtils';

const ROOT_URL = '';

export function* watchFetchLists() {
    yield takeLeading(actions.FETCH_LISTS, errorWrap(fetchListsSaga));
}

export function* watchFetchList() {
    yield takeLeading(actions.FETCH_LIST, errorWrap(fetchListSaga));
}

export function* watchCreateList() {
    yield takeEvery(actions.CREATE_LIST, errorWrap(createListSaga));
}

export function* watchUpdateList() {
    yield takeEvery(actions.UPDATE_LIST, errorWrap(updateListSaga));
}

export function* watchDeleteList() {
    yield takeEvery(actions.DELETE_LIST, errorWrap(deleteListSaga));
}

export function* watchCreateListItem() {
    yield takeEvery(actions.CREATE_LIST_ITEM, errorWrap(createListItemSaga));
}

export function* watchUpdateListItem() {
    yield takeEvery(actions.UPDATE_LIST_ITEM, errorWrap(updateListItemSaga));
}

export function* watchDeleteListItem() {
    yield takeEvery(actions.DELETE_LIST_ITEM, errorWrap(deleteListItemSaga));
}

export function* fetchListsSaga() {
    yield put(loading(true));
    yield spawn(appMetricSaga, appMetric('API: Fetch Lists'));
    const response = yield call(api.get, `${ ROOT_URL }/hopto/list`);
    const payload = response ? response.data : {};
    yield put({ type: FETCH_LISTS_RESPONSE, payload });
}

export function* fetchListSaga(action) {
    yield put(loading(true));
    yield put(appMetric('API: Fetch List'));
    const response = yield call(api.get, `${ ROOT_URL }/hopto/list/${ action.id }`);
    const payload = response ? response.data : {};
    yield put({ type: FETCH_LIST_RESPONSE, payload });
}

export function* createListSaga(action) {
    yield put(appMetric('API: Create List'));
    const response = yield call(api.post, `${ ROOT_URL }/hopto/list`, action.values);
    const payload = response ? response.data : {};
    yield put({ type: CREATE_LIST_RESPONSE, payload });

    if (action.callback) {
        action.callback();
    }

    yield put(successAlert('List successfully saved!'));
}

export function* updateListSaga(action) {
    yield put(appMetric('API: Update List'));
    const response = yield call(api.post, `${ ROOT_URL }/hopto/list`, action.values);
    const payload = response ? response.data : {};
    yield put({ type: UPDATE_LIST_RESPONSE, payload });

    if (action.callback) {
        action.callback();
    }

    yield put(successAlert('List successfully saved!'));
}

export function* deleteListSaga(action) {
    yield put(appMetric('API: Delete List'));
    yield call(api.delete, `${ ROOT_URL }/hopto/list/${ action.list.id }`);
    yield put({ type: DELETE_LIST_RESPONSE, payload: action.list });

    if (action.callback) {
        action.callback();
    }

    yield put(successAlert('List successfully deleted!'));
}

export function* createListItemSaga(action) {
    yield put(appMetric('API: Create List Item'));
    const response = yield call(api.post, `${ ROOT_URL }/hopto/list/item`, action.values);
    const payload = response ? response.data : {};
    yield put({ type: CREATE_LIST_ITEM_RESPONSE, payload });

    if (action.callback) {
        action.callback();
    }

    yield put(successAlert('List Item successfully saved!'));
}

export function* updateListItemSaga(action) {
    yield put(appMetric('API: Update List Item'));
    const response = yield call(api.post, `${ ROOT_URL }/hopto/list/item`, action.values);
    const payload = response ? response.data : {};
    yield put({ type: UPDATE_LIST_ITEM_RESPONSE, payload });

    if (action.callback) {
        action.callback();
    }

    yield put(successAlert('List Item successfully saved!'));
}

export function* deleteListItemSaga(action) {
    yield put(appMetric('API: Delete List Item'));
    yield call(api.delete, `${ ROOT_URL }/hopto/list/item/${ action.listItem.id }`);
    yield put({ type: DELETE_LIST_ITEM_RESPONSE, payload: action.listItem });

    if (action.callback) {
        action.callback();
    }

    yield put(successAlert('List Item successfully deleted!'));
}
