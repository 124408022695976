import 'regenerator-runtime/runtime';
import { call, put, spawn, takeEvery, takeLeading } from 'redux-saga/effects';
import * as actions from '../actions';
import {
    appMetric,
    FETCH_USER_PREFS_RESPONSE,
    loading, SAVE_USER_PREFS_RESPONSE, successAlert
} from '../actions';
import { appMetricSaga } from './metricsSaga';
import api from '../services/api';
import { errorWrap } from './sagaUtils';

const ROOT_URL = '';

export function* watchFetchUserPrefs() {
    yield takeLeading(actions.FETCH_USER_PREFS, errorWrap(fetchUserPrefsSaga));
}

export function* watchSaveUserPrefs() {
    yield takeEvery(actions.SAVE_USER_PREFS, errorWrap(saveUserPrefsSaga));
}

export function* fetchUserPrefsSaga() {
    yield put(loading(true));
    yield spawn(appMetricSaga, appMetric('API: Fetch User Preferences'));
    const response = yield call(api.get, `${ ROOT_URL }/hopto/user/preferences`);
    const payload = response ? response.data : {};
    yield put({ type: FETCH_USER_PREFS_RESPONSE, payload });
}

export function* saveUserPrefsSaga(action) {
    yield put(appMetric('API: Save User Preferences'));
    const response = yield call(api.put, `${ ROOT_URL }/hopto/user/preferences`, action.values);
    const payload = response ? response.data : {};
    yield put({ type: SAVE_USER_PREFS_RESPONSE, payload });

    if (action.callback) {
        action.callback();
    }

    yield put(successAlert('Preferences successfully saved!'));
}
