import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Form } from 'semantic-ui-react';
import { changePassword } from '../../actions/authActions';
import { useForm } from 'react-hook-form';
import AuthPageWrapper from '../wrapper/AuthPageWrapper';

const ChangePassword = () => {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const user = useSelector((state) => state.auth.user ? state.auth.user : null);

    const handleChangePassword = (data) => {
        dispatch(changePassword(user.username, data.currentPassword, data.newPassword, () => {
            navigate('/todos');
        }));
    };

    return (
        <AuthPageWrapper>
            <div className="col-md-12">
                <div className="card card-container">
                    <Form onSubmit={ handleSubmit(handleChangePassword) }>

                        <Form.Field>
                            <label htmlFor="currentPassword">Current Password</label>
                            <input type="password" placeholder="Current Password" { ...register('currentPassword', { required: true }) } />
                            { errors.password &&
                                <div className="ui pointing above prompt label" role="alert" aria-atomic="true">Please enter current password</div>
                            }
                        </Form.Field>

                        <Form.Field>
                            <label htmlFor="newPassword">New Password</label>
                            <input type="password" placeholder="New Password" { ...register('newPassword', { required: true }) } />
                            { errors.password &&
                                <div className="ui pointing above prompt label" role="alert" aria-atomic="true">Please enter new password</div>
                            }
                        </Form.Field>

                        <Button className="primary" type="submit">Change Password</Button>
                    </Form>
                </div>
            </div>
        </AuthPageWrapper>
    );
};

export default ChangePassword;
