import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompletedInsights } from '../../../actions';
import { Form, Grid } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Todo from '../../todo/Todo';

function DateDoneInsights() {

    const [startDate, setStartDate] = useState(new Date());

    const tags = useSelector((state) => state.tags.list ? state.tags.list : null);
    const [todoTagFilters, setTodoTagFilters] = useState([]);
    const completedInsights = useSelector((state) => state.insights.completed);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCompletedInsights(startDate));
    }, [startDate]);

    const tagOptions = tags ? tags.map(tag => {
        return { key: tag.tag, text: tag.tag, value: tag.tag };
    }) : {};

    const filterByTag = (event, { value }) => {
        setTodoTagFilters(value);
    };

    const isTodoTagsVisible = (todo) => {
        if (!todoTagFilters || todoTagFilters.length === 0) return true;
        if (!todo.tags || todo.tags.length === 0) return false;
        for (const tagKey in todo.tags) {
            if (todoTagFilters.includes(todo.tags[tagKey])) return true;
        }
        return false;
    };

    const renderTagSelect = () => {
        return (
            <Form.Select
                clearable
                multiple
                selection
                options={ tagOptions }
                value={ todoTagFilters }
                placeholder='Tag'
                onChange={ filterByTag }/>);
    };

    const renderTodoCards = () => {
        return completedInsights
            .filter((todo) => isTodoTagsVisible(todo))
            .map((todo) => {
                return (
                    <Grid.Column key={ todo.id }>
                        <Todo todo={ todo }/>
                    </Grid.Column>
                );
            });
    };

    return (
        <div>

            <Form>
                <Form.Group>
                    <Form.Field>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)} />
                    </Form.Field>
                    { renderTagSelect() }
                </Form.Group>
            </Form>

            { completedInsights &&
                <div>
                    <Grid columns={ 4 } className="computer only">
                        { renderTodoCards() }
                    </Grid>

                    <Grid columns={ 3 } className="tablet mobile only stackable">
                        { renderTodoCards() }
                    </Grid>
                </div>
            }
        </div>
    );
}

export default DateDoneInsights;
