import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTodoByDateInsights } from '../../../actions';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import 'react-datepicker/dist/react-datepicker.css';
import {Form, FormGroup} from 'semantic-ui-react';
import { getChartColor } from '../ChartUtils';
import {getDaysAgoDayString, getMonthsAgoString} from '../../../utils/dateUtils';
import tokens from '../../style.module.css';

function TodoDateInsights() {

    const dailyInsights = useSelector((state) => state.insights.todosByDate);
    const dispatch = useDispatch();

    const [movingAvg, setMovingAvg] = useState(1);
    const [timePeriod, setTimePeriod] = useState(getDaysAgoDayString(7));

    useEffect(() => {
        dispatch(fetchTodoByDateInsights(timePeriod));
    }, [dispatch, timePeriod]);

    const movingAvgOptions = () => {
        const options = [];
        options.push({ key: 1, text: 'No Moving Average', value: 1 });
        if (dailyInsights.length > 10) {
            options.push({ key: 5, text: '5 day Moving Average', value: 5 });
        }
        if (dailyInsights.length > 30) {
            options.push({ key: 11, text: '11 day Moving Average', value: 11 });
        }
        if (dailyInsights.length > 50) {
            options.push({ key: 19, text: '19 day Moving Average', value: 19 });
        }
        return options;
    };

    const timePeriodOptions = () => {
        const options = [];
        options.push({ key: 1, text: '1 Week', value: getDaysAgoDayString(7) });
        options.push({ key: 2, text: '1 Month', value: getMonthsAgoString(1) });
        options.push({ key: 3, text: '3 Months', value: getMonthsAgoString(3) });
        options.push({ key: 4, text: '1 Year', value: getMonthsAgoString(12) });
        options.push({ key: 5, text: 'All', value: null });
        return options;
    };

    const handleChangeMovingAvg = (e, { value }) => setMovingAvg(value);

    const handleChangeTimePeriod = (e, { value }) => setTimePeriod(value);

    const graphData = () => {
        const gData = [];

        if (movingAvg > 1) {
            for (let x = 0; x < dailyInsights.length; x++) {
                let runningTotal = 0;
                let averageOf = 0;
                for (let y = x - Math.floor(movingAvg / 2); y <= x + Math.floor(movingAvg / 2); y++) {
                    if (y >= 0 && y < dailyInsights.length) {
                        let cur = dailyInsights[y];
                        runningTotal = runningTotal + cur.completed;
                        averageOf = averageOf + 1;
                    }
                }
                gData.push({
                    name: dailyInsights[x].dateString,
                    completed: dailyInsights[x].completed,
                    movingAvg: runningTotal / averageOf
                });
            }
        } else {
            dailyInsights.forEach((insight) => {
                gData.push({ name: insight.dateString, completed: insight.completed });
            });
        }

        return gData;
    };

    return (

        <div>
            { dailyInsights &&
                <>
                    { dailyInsights.length > 10 &&
                        <>
                            <Form>
                                <FormGroup widths='equal'>
                                    <Form.Select
                                        fluid
                                        options={ movingAvgOptions() }
                                        defaultValue={ movingAvgOptions()[0]['value'] }
                                        placeholder='Moving Avg'
                                        onChange={ handleChangeMovingAvg }
                                    />
                                    <Form.Select
                                        inline
                                        fluid
                                        options={ timePeriodOptions() }
                                        defaultValue={ timePeriodOptions()[0]['value'] }
                                        placeholder='Time Period'
                                        onChange={ handleChangeTimePeriod }
                                    />
                                </FormGroup>
                            </Form>
                        </>
                    }

                    { dailyInsights.length <= 10 &&
                        <>
                            <Form className={tokens.rb1}>
                                <Form.Select
                                    fluid
                                    options={ timePeriodOptions() }
                                    defaultValue={ timePeriodOptions()[0]['value'] }
                                    placeholder='Time Period'
                                    onChange={ handleChangeTimePeriod }
                                />
                            </Form>
                        </>
                    }

                    <div style={ { 'width': '100%', 'height': '500px', 'backgroundColor': '#ffffff' } }>
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart
                                width={ 500 }
                                height={ 300 }
                                data={ graphData() }
                                margin={ {
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                } }
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="name"/>
                                <YAxis/>
                                <Tooltip/>
                                <Legend/>
                                <Line type="monotone" dataKey="completed" stroke="#82ca9d"/>
                                { movingAvg > 1 &&
                                    <Line type="monotone" dataKey="movingAvg" stroke={ getChartColor(2) }/>
                                }
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </>
            }
        </div>
    );
}

export default TodoDateInsights;
