import axiosInstance from './api';
import TokenService from './token.service';
import { logout, refreshTokenSuccess } from '../actions/authActions';

const setup = (store) => {
    axiosInstance.interceptors.request.use(
        (config) => {
            const token = TokenService.getLocalAccessToken();
            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token;  // for Spring Boot back-end
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    const { dispatch } = store;
    axiosInstance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;

            if (originalConfig.url !== '/api/auth/signin' && originalConfig.url !== '/api/auth/refreshtoken' && err.response) {
                // Access Token was expired
                // if (err.response.status === 404 && originalConfig.url === "/api/auth/refreshtoken") {
                //     dispatch(logout());
                if (err.response.status === 401 && !originalConfig.headers.tokenRefreshed) {
                    try {
                        originalConfig.headers = {...originalConfig.headers, 'tokenRefreshed': true};
                        const rs = await axiosInstance.post('/api/auth/refreshtoken', {
                            refreshToken: TokenService.getLocalRefreshToken(),
                        });

                        const { accessToken } = rs.data;

                        dispatch(refreshTokenSuccess(accessToken));
                        TokenService.updateLocalAccessToken(accessToken);

                        return axiosInstance(originalConfig);
                    } catch (_error) {
                        dispatch(logout());
                        return Promise.reject(_error);
                    }
                }
            }

            return Promise.reject(err);
        }
    );
};

export default setup;
