import {
    CREATE_TODO_RESPONSE,
    DELETE_TODO_RESPONSE,
    FETCH_SORT_ORDER_RESPONSE,
    FETCH_TODO_RESPONSE,
    FETCH_TODOS_RESPONSE,
    RESET_STATE,
    SAVE_SORT_ORDER_RESPONSE,
    UPDATE_TODO_RESPONSE
} from '../actions';

function todosReducer(state = { sortOrder: null }, action) {
    switch (action.type) {
    case FETCH_TODOS_RESPONSE:
        return { ...state,
            list: action.payload,
            map: Object.fromEntries(action.payload.map(x => [x.id, x]))
        };
    case FETCH_TODO_RESPONSE:
    case CREATE_TODO_RESPONSE:
        return { ...state,
            list: [ ...state.list, action.payload],
            map: { ...state.map, [action.payload.id]: action.payload}
        };
    case DELETE_TODO_RESPONSE: {
        let remaining = { ...state.map };
        delete remaining[action.payload];
        return {
            ...state,
            list: state.list.filter(todo => {
                return todo.id !== action.payload;
            }),
            map: remaining
        };
    }
    case UPDATE_TODO_RESPONSE: {
        // Loop on a list
        let newList = state.list.map(function (item) {
            return item.id === action.payload.id ? action.payload : item;
        });
        return { ...state,
            list: newList,
            map: { ...state.map, [action.payload.id]: action.payload }
        };
    }
    case FETCH_SORT_ORDER_RESPONSE:
        return { ...state, sortOrder: action.payload ? action.payload : { order: [] } };
    case SAVE_SORT_ORDER_RESPONSE:
        return { ...state, sortOrder: action.payload };
    case RESET_STATE:
        return { sortOrder: null };
    default:
        return state;
    }
}

export default todosReducer;
