import {
    SUCCESS_ALERT_SAVE,
    SUCCESS_ALERT_CLEAR,
    ERROR_ALERT_SAVE,
    ERROR_ALERT_CLEAR,
    LOADING,
    RESET_STATE,
    ACTION_REQUIRED_ALERT_SAVE, ACTION_REQUIRED_ALERT_CLEAR
} from '../actions';

function alertsReducer(state = {}, action) {
    switch (action.type) {
    case SUCCESS_ALERT_SAVE:
        return { ...state, successAlert: action.message };
    case SUCCESS_ALERT_CLEAR:
        return { ...state, successAlert: null };
    case ERROR_ALERT_SAVE:
        return { ...state, errorAlert: action.message };
    case ERROR_ALERT_CLEAR:
        return { ...state, errorAlert: null };
    case ACTION_REQUIRED_ALERT_SAVE:
        return { ...state, actionRequiredAlert: action.message };
    case ACTION_REQUIRED_ALERT_CLEAR:
        return { ...state, actionRequiredAlert: null };
    case LOADING:
        return { ...state, loading: action.loading };
    case RESET_STATE:
        return {};
    default:
        return state;
    }
}

export default alertsReducer;
