import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchList, fetchLists} from '../../actions';
import Alerts from '../Alerts';
import AuthPageWrapper from '../wrapper/AuthPageWrapper';
import ListHeader from './ListHeader';
import {Grid} from 'semantic-ui-react';
import ListCard from './ListCard';
import ListItem from './ListItem';

function ListItems() {

    const list = useSelector((state) => state.ux.listSelectedList ? state.ux.listSelectedList : null);
    const view = useSelector(state => state.ux.listSelectedView ? state.ux.listSelectedView : 'items');
    const listItems = useSelector((state) => state.lists.listItems ? state.lists.listItems : null);
    //const user = useSelector((state) => state.auth.user ? state.auth.user : null);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchLists());
        //this.props.appMetric("PAGE: Todos")
    }, [dispatch]);

    useEffect(() => {
        if (list) {
            dispatch(fetchList(list.id));
        }
    }, [list]);

    const renderListItems = () => {
        if (!listItems) return [];

        return listItems.map((listItem) => {
            return (
                <Grid.Column key={ listItem.id }>
                    { view === 'cards' &&
                        <ListCard listItem={ listItem }/>
                    }
                    { view === 'items' &&
                        <ListItem listItem={ listItem }/>
                    }
                </Grid.Column>
            );
        });
    };

    return (
        <AuthPageWrapper>
            <div>

                <Alerts/>

                <ListHeader/>

                <Grid columns={ 4 } className="computer only">
                    { renderListItems() }
                </Grid>

                <Grid columns={ 3 } className="tablet mobile only stackable">
                    { renderListItems() }
                </Grid>

            </div>
        </AuthPageWrapper>
    );
}

export default ListItems;
