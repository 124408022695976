import 'regenerator-runtime/runtime';
import { call, put, spawn, takeEvery, takeLeading } from 'redux-saga/effects';
import * as actions from '../actions';
import {
    appMetric,
    CREATE_TAG_RESPONSE,
    DELETE_TAG_RESPONSE,
    FETCH_TAGS_RESPONSE,
    loading,
    successAlert
} from '../actions';
import { appMetricSaga } from './metricsSaga';
import api from '../services/api';
import { errorWrap } from './sagaUtils';

const ROOT_URL = '';

export function* watchFetchTags() {
    yield takeLeading(actions.FETCH_TAGS, errorWrap(fetchTagsSaga));
}

export function* watchCreateTag() {
    yield takeEvery(actions.CREATE_TAG, errorWrap(createTagSaga));
}

export function* watchDeleteTag() {
    yield takeEvery(actions.DELETE_TAG, errorWrap(deleteTagSaga));
}

export function* fetchTagsSaga() {
    yield put(loading(true));
    yield spawn(appMetricSaga, appMetric('API: Fetch Tags'));
    const response = yield call(api.get, `${ ROOT_URL }/hopto/tag`);
    const payload = response ? response.data : {};
    yield put({ type: FETCH_TAGS_RESPONSE, payload });
}

export function* createTagSaga(action) {
    yield put(appMetric('API: Create Todo'));
    const response = yield call(api.post, `${ ROOT_URL }/hopto/tag`, action.values);
    if (action.callback) {
        action.callback();
    }
    const payload = response ? response.data : {};
    yield put({ type: CREATE_TAG_RESPONSE, payload });
    yield put(successAlert('Tag successfuly saved!'));
}

export function* deleteTagSaga(action) {
    yield put(appMetric('API: Delete Tag'));
    yield call(api.delete, `${ ROOT_URL }/hopto/tag/${ action.id }`);
    if (action.callback) {
        action.callback();
    }
    yield put({ type: DELETE_TAG_RESPONSE, payload: action.id });
    yield put(successAlert('Tag successfuly deleted!'));
}
