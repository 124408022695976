import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';
import { errorAlert, loading } from '../actions';

export const errorWrap = (saga, ...args) => function* (action) {
    try {
        yield call(saga, ...args, action);
    } catch (err) {
        if (err.response && err.response.status !== 401) {
            yield put(errorAlert('An error occurred, please try again!'));
            console.log(err);
        }
    } finally {
        yield put(loading(false));
    }
};
