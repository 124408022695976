import AuthPageWrapper from '../wrapper/AuthPageWrapper';
import Alerts from '../Alerts';
import React, { useState } from 'react';
import SearchBar from './SearchBar';
import ImageList from './ImageList';
import unsplash from '../../services/unsplash';
import { Button } from 'semantic-ui-react';

function Background() {

    const [term, setTerm] = useState('');
    const [images, setImages] = useState([]);
    const [page, setPage] = useState(1);

    const onSearchSubmit = async (term) => {
        const response = await unsplash.get('/search/photos', {
            params: { query: term, content_filter: 'high', per_page: 25 }
        });

        setTerm(term);
        setPage(1);
        setImages(response.data.results);
    };

    const loadMoreSubmit = async () => {
        const nextPage = page + 1;

        const response = await unsplash.get('/search/photos', {
            params: { query: term, content_filter: 'high', per_page: 25, page: nextPage }
        });

        setPage(nextPage);
        setImages(images.concat(response.data.results));
    };

    return (
        <AuthPageWrapper>
            <div>

                <Alerts/>

                <SearchBar onSubmit={ onSearchSubmit }/>
                <ImageList images={ images } />
                { images && images.length > 0 &&
                    <Button onClick={ loadMoreSubmit } >Load More Images</Button>
                }


            </div>

        </AuthPageWrapper>
    );
}

export default Background;
