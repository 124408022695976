import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTag } from '../../actions';
import { Button, Card, Icon } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function Tag(props) {

    const preferences = useSelector(state => state.user.preferences);

    const { tag } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isHovering, setIsHovering] = useState(false);

    const onDeleteClick = (id) => {
        dispatch(deleteTag(id));
    };

    const handleEdit = () => {
        navigate('/todos/new-tag', { state: { tag: tag } });
    };

    if (preferences === null) return;

    const cardTitleHex = preferences.cardTitleHex ? preferences.cardTitleHex : '#546e7a';
    const cardBackgroundRgba = preferences.cardBackgroundRgba ? preferences.cardBackgroundRgba : 'rgba(255,255,255,1.0)';

    return (
        <Card fluid
            onMouseOver={ () => setIsHovering(true) }
            onMouseOut={ () => setIsHovering(false) }
            style={ { backgroundColor: cardBackgroundRgba } }>
            <Card.Content>
                <Card.Header style={ { color: cardTitleHex } }>
                    { isHovering &&
                        <div style={ { float: 'right', marginRight: '-1rem', marginTop: '-.5rem' } }>
                            <Icon circular color='grey' link name='pencil alternate' onClick={ handleEdit }/>
                        </div>
                    }
                    { tag.tag }

                </Card.Header>
            </Card.Content>
            <Card.Content extra className='right aligned'>
                <div>
                    <Button onClick={ () => onDeleteClick(tag.id) }>Delete</Button>
                </div>
            </Card.Content>
        </Card>
    );
}

Tag.propTypes = {
    tag: PropTypes.object
};

export default Tag;
