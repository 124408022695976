import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { createTag, deleteTag } from '../../../actions';
import { Button, Form } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import AuthPageWrapper from '../../wrapper/AuthPageWrapper';

function TagForm() {

    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const user = useSelector((state) => state.auth.user ? state.auth.user : null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let defaultValues = {};
        defaultValues.user = user.username;
        if (location.state && location.state.tag) {
            const tag = location.state.tag;
            defaultValues.id = tag.id;
            defaultValues.tag = tag.tag;
        }
        reset({ ...defaultValues });
    }, [user.username, location.state, reset]);

    const onDeleteClick = () => {
        dispatch(deleteTag(location.state.tag.id, () => {navigate('/tags');}));
    };

    const onSubmit = (data) => {
        dispatch(createTag(data, () => {
            navigate('/tags');
        }));
        //alert(JSON.stringify(data, null, 2))
    };

    return (
        <AuthPageWrapper>
            <Form onSubmit={ handleSubmit(onSubmit) }>
                <Form.Field className={ `${ errors.description ? 'error' : '' }` }>
                    <label htmlFor="tag">Description</label>
                    <input placeholder="Tag" { ...register('tag', { required: true }) } />
                    { errors.description &&
                        <div className="ui pointing above prompt label" role="alert" aria-atomic="true">Please enter a tag</div> }
                </Form.Field>


                {location.state && location.state.todo &&
                    <Button type="button"
                        className="negative"
                        onClick={onDeleteClick}>
                        Delete Todo
                    </Button>
                }

                <Button type="button"
                    className="secondary"
                    onClick={ (e) => {
                        e.preventDefault();
                        navigate('/tags');
                    } }>
                    Cancel
                </Button>
                <Button className="primary" type="submit">Submit</Button>
            </Form>
        </AuthPageWrapper>
    );

}

export default TagForm;
