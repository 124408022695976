import 'regenerator-runtime/runtime';
import { call, put, spawn, takeLeading } from 'redux-saga/effects';
import * as actions from '../actions';
import {
    appMetric,
    FETCH_COMPLETED_INSIGHTS_RESPONSE,
    FETCH_HABIT_INSIGHTS_RESPONSE,
    FETCH_TODO_BY_DATE_INSIGHTS_RESPONSE, FETCH_TODO_BY_TAG_INSIGHTS_RESPONSE,
    loading
} from '../actions';
import { appMetricSaga } from './metricsSaga';
import api from '../services/api';
import { errorWrap } from './sagaUtils';
import { format } from 'date-fns';

const ROOT_URL = '';

export function* watchFetchTodoByDateInsights() {
    yield takeLeading(actions.FETCH_TODO_BY_DATE_INSIGHTS, errorWrap(fetchTodoByDateInsightsSaga));
}

export function* watchFetchTodoByTagInsights() {
    yield takeLeading(actions.FETCH_TODO_BY_TAG_INSIGHTS, errorWrap(fetchTodoByTagInsightsSaga));
}

export function* watchFetchHabitInsights() {
    yield takeLeading(actions.FETCH_HABIT_INSIGHTS, errorWrap(fetchHabitInsightsSaga));
}

export function* watchFetchCompletedInsights() {
    yield takeLeading(actions.FETCH_COMPLETED_INSIGHTS, errorWrap(fetchCompletedInsightsSaga));
}

export function* fetchTodoByDateInsightsSaga(action) {
    yield put(loading(true));
    yield spawn(appMetricSaga, appMetric('API: Fetch Todo Insights'));
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let url = `${ ROOT_URL }/insights/todoDailyCount?timezone=` + encodeURIComponent(userTimezone);
    if (action.start) {
        url = url + `&start=${action.start}`;
    }
    const response = yield call(api.get, url);
    const payload = response ? response.data : {};
    yield put({ type: FETCH_TODO_BY_DATE_INSIGHTS_RESPONSE, payload });
}

export function* fetchTodoByTagInsightsSaga() {
    yield put(loading(true));
    yield spawn(appMetricSaga, appMetric('API: Fetch Todo Insights'));
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = yield call(api.get, `${ ROOT_URL }/insights/todoTagCount?timezone=` + encodeURIComponent(userTimezone));
    const payload = response ? response.data : {};
    yield put({ type: FETCH_TODO_BY_TAG_INSIGHTS_RESPONSE, payload });
}

export function* fetchHabitInsightsSaga() {
    yield put(loading(true));
    yield spawn(appMetricSaga, appMetric('API: Fetch Habit Insights'));
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = yield call(api.get, `${ ROOT_URL }/insights/habit?timezone=` + encodeURIComponent(userTimezone));
    const payload = response ? response.data : {};
    yield put({ type: FETCH_HABIT_INSIGHTS_RESPONSE, payload });
}

export function* fetchCompletedInsightsSaga(action) {
    yield put(loading(true));
    yield spawn(appMetricSaga, appMetric('API: Fetch Completed Insights'));
    const dateString = format(action.date, 'yyyyMMdd');
    const response = yield call(api.get, `${ ROOT_URL }/insights/completed-on/${ dateString}`);
    const payload = response ? response.data : {};
    yield put({ type: FETCH_COMPLETED_INSIGHTS_RESPONSE, payload });
}
