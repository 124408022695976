import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    completeTodo,
    electFailTodo,
    incrementCountTodo,
    decrementCountTodo,
    setGoalProgressTodo
} from '../../actions';
import { Button, Card, Icon, Label } from 'semantic-ui-react';
import { capitalizeFirstLetter } from '../../utils/stringUtils';
import {
    isCompleted,
    isFailed,
    isDone,
    todoCount,
    todoRepeats,
    todoTracking, isType, CardTypes
} from '../../utils/todoUtils';
import PropTypes from 'prop-types';
import { getDateDisplayStringFromDayString } from '../../utils/dateUtils';

function Todo(props) {

    const preferences = useSelector(state => state.user.preferences);

    const { todo } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [count, setCount] = useState();
    const [isHovering, setIsHovering] = useState(false);

    const onCompleteClick = (todo) => {
        dispatch(completeTodo(todo));
    };

    const onElectFailClick = (todo) => {
        dispatch(electFailTodo(todo));
    };

    const onIncrementCountClick = (todo) => {
        dispatch(incrementCountTodo(todo));
    };

    const onDecrementCountClick = (todo) => {
        dispatch(decrementCountTodo(todo));
    };

    const onSetCount = (id) => {
        dispatch(setGoalProgressTodo(id, count));
        setCount('');
    };

    const onChangeCount = (e) => {
        const strAfterDot = e.target.value.split('.', 2)[1];
        const decimals = todo.goalDecimals ? todo.goalDecimals : 2;

        if (!strAfterDot || strAfterDot.length <= decimals){
            setCount(e.target.value);
        }
    };

    const getTagsString = (todo) => {
        let tagString = todo.tags[0];
        for (let i = 1; i < todo.tags.length; i++) {
            tagString = tagString + ' | ' + todo.tags[i];
        }
        return tagString;
    };

    const getLastGoalOn = (todo) => {
        if (!todo.goalOn || Object.keys(todo).length === 0) return '--';
        const entries = Object.values(todo.goalOn);
        return entries[entries.length - 1];
    };

    const handleEdit = () => {
        if (!todoRepeats(todo)  && todo.trackingType != 'number') {
            navigate('/todos/new-todo', {state: { todo: todo }});
        } else if (!todoRepeats(todo) && todo.trackingType === 'number') {
            navigate('/todos/new-goal', {state: { todo: todo }});
        } else if (todoRepeats(todo)) {
            navigate('/todos/new-habit', {state: { todo: todo }});
        }
    };

    if (preferences === null) return;

    const cardTitleHex = preferences.cardTitleHex ? preferences.cardTitleHex : '#546e7a';
    const cardBackgroundRgba = preferences.cardBackgroundRgba ? preferences.cardBackgroundRgba : 'rgba(255,255,255,1.0)';

    return (
        <Card fluid
            color={`${isCompleted(todo) ? 'green' : isFailed(todo) ? 'red' : 'grey'}`}
            onMouseOver={() => setIsHovering(true)}
            onMouseOut={() => setIsHovering(false)}
            style={{backgroundColor: cardBackgroundRgba}}>
            <Card.Content>
                <Card.Header style={{color: cardTitleHex}}>
                    {isHovering &&
              <div style={{float: 'right', marginRight: '-1rem', marginTop: '-.5rem'}}>
                  <Icon circular color='grey' link name='pencil alternate' onClick={handleEdit}/>
              </div>
                    }
                    { todo.description }

                </Card.Header>
                {todoRepeats(todo) &&
                    <Card.Meta  style={{color: cardTitleHex}}>{capitalizeFirstLetter(todo.repeatsType)}</Card.Meta>
                }
                {todo.tags && todo.tags.length > 0 &&
                    <Card.Meta  style={{color: cardTitleHex}}>{capitalizeFirstLetter(getTagsString(todo))}</Card.Meta>
                }
                {todo.dueDate &&
                    <Card.Meta  style={{color: cardTitleHex}}>Due: {getDateDisplayStringFromDayString(todo.dueDate)}</Card.Meta>
                }

                <Card.Description style={{color: cardTitleHex}}>
                    {todo.details}

                    {todo.trackingType === 'number' && todo.goalOn &&
              <>
                  <span>Last Count: {getLastGoalOn(todo)}</span>
                  <br />
                  <span>Goal: {todo.countGoal}</span>
              </>
                    }
                </Card.Description>
            </Card.Content>
            { todoTracking(todo) &&
      <Card.Content extra className='right aligned'>
          <div>

              {isCompleted(todo) && !isType(todo, CardTypes.goal) &&
              <Label color="green">
                Done!
              </Label>
              }

              {isFailed(todo) &&
              <Label color="red">
                Fail!
              </Label>
              }

              {!todoRepeats(todo) && todo.trackingType !== 'number' && !todo.completedOn &&
          <Button onClick={() => onCompleteClick(todo)}>Done</Button>
              }

              {todoRepeats(todo) && todo.trackingType === 'finished' && !isDone(todo) &&
          <Button icon onClick={() => onCompleteClick(todo)}>
              <Icon name='checkmark' />
          </Button>
              }

              {todoRepeats(todo) && todo.trackingType === 'finished_fail' && !isDone(todo) &&
          <React.Fragment>
              <Button icon onClick={() => onElectFailClick(todo)}>
                  <Icon name='times' />
              </Button>
              <Button icon onClick={() => onCompleteClick(todo)}>
                  <Icon name='checkmark' />
              </Button>
          </React.Fragment>
              }

              {todoRepeats(todo) && (todo.trackingType === 'count' || todo.trackingType === 'count_goal') &&
            <Button.Group>
                <Button icon onClick={() => onDecrementCountClick(todo)}>
                    <Icon name='minus' />
                </Button>
                <Button>{todoCount(todo)}</Button>
                <Button icon onClick={() => onIncrementCountClick(todo)}>
                    <Icon name='plus' />
                </Button>
            </Button.Group>
              }

              {todo.trackingType === 'number' &&
              <div className="ui form">
                  <div className="inline fields">
                      <div className="field">
                          <input
                              type="number"
                              placeholder="Count for Today"
                              value={count}
                              onChange={onChangeCount}
                          />
                      </div>
                      <div className="field">
                          <Button icon onClick={() => onSetCount(todo.id)}>
                              <Icon name='checkmark' />
                          </Button>
                      </div>
                  </div>
              </div>
              }

          </div>
      </Card.Content>
            }
        </Card>
    );
}

Todo.propTypes = {
    todo: PropTypes.object
};

export default Todo;
