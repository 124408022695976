export const SAVE_FEEDBACK = 'SAVE_FEEDBACK';
export const SAVE_FEEDBACK_RESPONSE = 'SAVE_FEEDBACK_RESPONSE';

export function saveFeedback(values, callback) {
    return {
        type: SAVE_FEEDBACK,
        values,
        callback
    };
}
