import 'regenerator-runtime/runtime';
import { call, put, spawn, takeEvery, takeLeading } from 'redux-saga/effects';
import * as actions from '../actions';
import {
    appMetric,
    errorAlert,
    successAlert,
    loading,
    LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT_SUCCESS,
    REGISTER_FAIL, REGISTER_SUCCESS,
    RESET_STATE, COMPLETE_REGISTRATION_SUCCESS, COMPLETE_REGISTRATION_FAIL,
    PASSWORD_RESET_SUCCESS, PASSWORD_RESET_FAIL, actionRequiredAlertSave, actionRequiredAlertClear
} from '../actions';
import { appMetricSaga } from './metricsSaga';
import TokenService from '../services/token.service';
import api from '../services/api';

const ROOT_URL = '';

export function* watchRegister() {
    yield takeEvery(actions.REGISTER, registerSaga);
}

export function* watchCompleteRegistration() {
    yield takeLeading(actions.COMPLETE_REGISTRATION, completeRegistrationSaga);
}

export function* watchLogin() {
    yield takeEvery(actions.LOGIN, loginSaga);
}

export function* watchLogout() {
    yield takeEvery(actions.LOGOUT, logoutSaga);
}

export function* watchChangePassword() {
    yield takeEvery(actions.CHANGE_PASSWORD, changePasswordSaga);
}

export function* watchStartPasswordReset() {
    yield takeEvery(actions.START_PASSWORD_RESET, startPasswordResetSaga);
}

export function* watchPasswordReset() {
    yield takeEvery(actions.PASSWORD_RESET, passwordResetSaga);
}

export function* registerSaga(action) {
    yield put(loading(true));

    try {
        yield spawn(appMetricSaga, appMetric('API: Register'));
        const origin = window.location.origin;
        const response = yield call(api.post, `${ ROOT_URL }/api/auth/start-signup?origin=` + encodeURIComponent(origin), {
            username: action.username,
            email: action.email,
            password: action.password
        });

        yield put({ type: REGISTER_SUCCESS });
        yield put(actionRequiredAlertSave(response.data.message));
        if (action.callback) { action.callback(); }
    } catch (error) {
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        yield put({ type: REGISTER_FAIL });
        yield put(errorAlert(message));
    }

    yield put(loading(false));
}

export function* completeRegistrationSaga(action) {
    yield put(loading(true));

    try {
        yield spawn(appMetricSaga, appMetric('API: Register'));
        const response = yield call(api.post, `${ ROOT_URL }/api/auth/complete-signup`, {
            username: action.username,
            registrationCode: action.code
        });

        yield put({ type: COMPLETE_REGISTRATION_SUCCESS });
        yield put(actionRequiredAlertSave(response.data.message));
        if (action.callback) { action.callback(); }
    } catch (error) {
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        yield put({ type: COMPLETE_REGISTRATION_FAIL });
        yield put(errorAlert(message));
    }

    yield put(loading(false));
}

export function* loginSaga(action) {
    yield put(loading(true));

    try {
        yield spawn(appMetricSaga, appMetric('API: Login'));
        const response = yield call(api.post, `${ ROOT_URL }/api/auth/signin`, {
            username: action.username,
            password: action.password
        });
        const payload = response ? response.data : {};

        if (payload.accessToken) {
            TokenService.setUser(payload);
            yield put({ type: LOGIN_SUCCESS, payload: { user: payload } });
            yield put(actionRequiredAlertClear());
            if (action.callback) { action.callback(); }
        } else {
            yield put({ type: LOGIN_FAIL });
            yield put(errorAlert('An error occurred, please try again later.'));
        }
    } catch (x) {
        yield put({ type: LOGIN_FAIL });
        if (x.response.status === 401) {
            yield put(errorAlert('The username / password combination entered is not recognized.'));
        } else {
            yield put(errorAlert('An error occurred, please try again later.'));
        }
    }

    yield put(loading(false));
}

export function* logoutSaga() {
    yield put(loading(true));
    yield spawn(appMetricSaga, appMetric('API: Logout'));

    TokenService.removeUser();

    yield put({ type: LOGOUT_SUCCESS });
    yield put({ type: RESET_STATE });
}

export function* changePasswordSaga(action) {
    yield put(loading(true));

    try {
        yield spawn(appMetricSaga, appMetric('API: Login'));
        const response = yield call(api.post, `${ ROOT_URL }/api/auth/change-password`, {
            username: action.username,
            currentPassword: action.currentPassword,
            newPassword: action.newPassword

        });

        if (response.status === 200) {
            yield put(successAlert('Password changed successfully.'));
            if (action.callback) { action.callback(); }
        } else {
            yield put(errorAlert('An error occurred, please try again later.'));
        }
    } catch (x) {
        yield put(errorAlert('An error occurred, please try again later.'));
    }

    yield put(loading(false));
}

export function* startPasswordResetSaga(action) {
    yield put(loading(true));

    try {
        yield spawn(appMetricSaga, appMetric('API: Start password reset'));
        const origin = window.location.origin;
        const response = yield call(api.post, `${ ROOT_URL }/api/auth/start-pwd-reset?origin=` + encodeURIComponent(origin), {
            email: action.email
        });

        yield put(successAlert(response.data.message));
        if (action.callback) { action.callback(); }
    } catch (error) {
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        yield put(errorAlert(message));
    }

    yield put(loading(false));
}

export function* passwordResetSaga(action) {
    yield put(loading(true));

    try {
        yield spawn(appMetricSaga, appMetric('API: Password Reset'));
        const response = yield call(api.post, `${ ROOT_URL }/api/auth/complete-pwd-reset`, {
            username: action.username,
            password: action.password,
            passwordResetCode: action.code
        });

        yield put({ type: PASSWORD_RESET_SUCCESS });
        yield put(successAlert(response.data.message));
        if (action.callback) { action.callback(); }
    } catch (error) {
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        yield put({ type: PASSWORD_RESET_FAIL });
        yield put(errorAlert(message));
    }

    yield put(loading(false));
}
