export const FETCH_LISTS = 'FETCH_LISTS';
export const FETCH_LISTS_RESPONSE = 'FETCH_LISTS_RESPONSE';
export const FETCH_LIST = 'FETCH_LIST';
export const FETCH_LIST_RESPONSE = 'FETCH_LIST_RESPONSE';
export const CREATE_LIST = 'CREATE_LIST';
export const CREATE_LIST_RESPONSE = 'CREATE_LIST_RESPONSE';
export const UPDATE_LIST = 'UPDATE_LIST';
export const UPDATE_LIST_RESPONSE = 'UPDATE_LIST_RESPONSE';
export const DELETE_LIST = 'DELETE_LIST';
export const DELETE_LIST_RESPONSE = 'DELETE_LIST_RESPONSE';
export const CREATE_LIST_ITEM = 'CREATE_LIST_ITEM';
export const CREATE_LIST_ITEM_RESPONSE = 'CREATE_LIST_ITEM_RESPONSE';
export const UPDATE_LIST_ITEM = 'UPDATE_LIST_ITEM';
export const UPDATE_LIST_ITEM_RESPONSE = 'UPDATE_LIST_ITEM_RESPONSE';
export const DELETE_LIST_ITEM = 'DELETE_LIST_ITEM';
export const DELETE_LIST_ITEM_RESPONSE = 'DELETE_LIST_ITEM_RESPONSE';

export function fetchLists() {
    return {
        type: FETCH_LISTS
    };
}

export function fetchList(id) {
    return {
        type: FETCH_LIST,
        id: id,
    };
}

export function createList(values, callback) {
    return {
        type: CREATE_LIST,
        values,
        callback
    };
}

export function updateList(values, callback) {
    return {
        type: UPDATE_LIST,
        values,
        callback
    };
}

export function deleteList(list, callback) {
    return {
        type: DELETE_LIST,
        list,
        callback
    };
}

export function createListItem(values, callback) {
    return {
        type: CREATE_LIST_ITEM,
        values,
        callback
    };
}

export function updateListItem(values, callback) {
    return {
        type: UPDATE_LIST_ITEM,
        values,
        callback
    };
}

export function deleteListItem(listItem, callback) {
    return {
        type: DELETE_LIST_ITEM,
        listItem,
        callback
    };
}
