import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Form } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import PageWrapper from '../wrapper/PageWrapper';
import { startPasswordReset } from '../../actions/authActions';

const StartPasswordReset = () => {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const handleRegister = (data) => {
        dispatch(startPasswordReset(data.email, () => {
            navigate('/');
        }));
    };

    return (
        <PageWrapper>
            <div className="col-md-12">
                <div className="card card-container">
                    <Form onSubmit={ handleSubmit(handleRegister) }>
                        <Form.Field>
                            <label htmlFor="email">Email</label>
                            <input placeholder="Email" { ...register('email', { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i }) } />
                            { errors.email &&
                                <div className="ui pointing above prompt label" role="alert" aria-atomic="true">Please enter a valid email address</div>
                            }
                        </Form.Field>

                        <Button type="button"
                            onClick={ (e) => {
                                e.preventDefault();
                                navigate('/login');
                            } }>
                            Cancel
                        </Button>

                        <Button className="primary" type="submit">Reset Password</Button>
                    </Form>
                </div>
            </div>
        </PageWrapper>
    );
};

export default StartPasswordReset;
