export const SET_TODO_TYPE_FILTERS = 'SET_TODO_TYPE_FILTERS';
export const SET_TODO_TAG_FILTERS = 'SET_TODO_TAG_FILTERS';
export const SET_TODO_VIEW = 'SET_TODO_VIEW';
export const SET_SELECTED_LIST = 'SET_SELECTED_LIST';
export const SET_SELECTED_LIST_VIEW = 'SET_SELECTED_LIST_VIEW';

export function setTodoTypeFilters(filters) {
    return {
        type: SET_TODO_TYPE_FILTERS,
        filters
    };
}

export function setTodoTagFilters(filters) {
    return {
        type: SET_TODO_TAG_FILTERS,
        filters
    };
}

export function setTodoView(view) {
    return {
        type: SET_TODO_VIEW,
        view
    };
}

export function setSelectedList(list) {
    return {
        type: SET_SELECTED_LIST,
        list
    };
}

export function setSelectedListView(view) {
    return {
        type: SET_SELECTED_LIST_VIEW,
        view
    };
}