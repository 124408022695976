import 'regenerator-runtime/runtime';
import { takeEvery } from 'redux-saga/effects';
import * as actions from '../actions';

//const ROOT_URL = '/api';

export function* watchAppMetrics() {
    yield takeEvery(actions.APP_METRIC, appMetricSaga);
}

export function* appMetricSaga(action) {
    console.log(action.metric);
    yield;
    //const response = yield call(axios.post, `${ROOT_URL}/metrics/${action.metric}`);
}
