export const FETCH_TODO_BY_DATE_INSIGHTS = 'FETCH_TODO_BY_DATE_INSIGHTS';
export const FETCH_TODO_BY_DATE_INSIGHTS_RESPONSE = 'FETCH_TODO_BY_DATE_INSIGHTS_RESPONSE';
export const FETCH_TODO_BY_TAG_INSIGHTS = 'FETCH_TODO_BY_TAG_INSIGHTS';
export const FETCH_TODO_BY_TAG_INSIGHTS_RESPONSE = 'FETCH_TODO_BY_TAG_INSIGHTS_RESPONSE';
export const FETCH_HABIT_INSIGHTS = 'FETCH_HABIT_INSIGHTS';
export const FETCH_HABIT_INSIGHTS_RESPONSE = 'FETCH_HABIT_INSIGHTS_RESPONSE';
export const FETCH_COMPLETED_INSIGHTS = 'FETCH_COMPLETED_INSIGHTS';
export const FETCH_COMPLETED_INSIGHTS_RESPONSE = 'FETCH_COMPLETED_INSIGHTS_RESPONSE';

export function fetchTodoByDateInsights(start) {
    return {
        type: FETCH_TODO_BY_DATE_INSIGHTS,
        start
    };
}

export function fetchTodoByTagInsights() {
    return {
        type: FETCH_TODO_BY_TAG_INSIGHTS
    };
}

export function fetchHabitInsights() {
    return {
        type: FETCH_HABIT_INSIGHTS
    };
}

export function fetchCompletedInsights(date) {
    return {
        type: FETCH_COMPLETED_INSIGHTS,
        date: date
    };
}
