import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Grid, Icon } from 'semantic-ui-react';
import Alerts from '../Alerts';
import AuthPageWrapper from '../wrapper/AuthPageWrapper';
import { fetchLists } from '../../actions';
import { Link } from 'react-router-dom';
import List from './List';

function Lists() {

    const lists = useSelector((state) => state.lists.lists ? state.lists.lists : null);
    // const tags = useSelector((state) => state.tags.list ? state.tags.list : null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchLists());
        //this.props.appMetric("PAGE: Tags")
    }, [dispatch]);

    const renderLists = () => {
        return lists.map((list) => {
            return (
                <Grid.Column key={ list.id }>
                    <List list={ list }/>
                </Grid.Column>
            );
        });
    };

    return (
        <AuthPageWrapper>
            <div>

                <Alerts/>

                <Grid columns={ 2 } className="stackable">
                    <Grid.Column width={ 10 } className='top aligned'>
                        <div className="ui breadcrumb">
                            <Link to={'/'} className="section">Home</Link>
                            <i className="right arrow icon divider"></i>
                            <Link to={'/lists/list'} className="section">List Items</Link>
                            <i className="right arrow icon divider"></i>
                            <div className="active section">Lists</div>
                        </div>
                    </Grid.Column>

                    <Grid.Column width={ 6 } className='tablet computer only right aligned'>
                        <Form>
                            <Link to="/lists/new-list">
                                <Button icon labelPosition='left'>
                                    <Icon name='cube'/>
                                    New List
                                </Button>
                            </Link>
                        </Form>
                    </Grid.Column>

                    <Grid.Column width={ 6 } className='mobile only'>
                        <Form>
                            <Link to="/lists/new-list">
                                <Button icon labelPosition='left'>
                                    <Icon name='cube'/>
                                    New List
                                </Button>
                            </Link>
                        </Form>
                    </Grid.Column>
                </Grid>

                <Grid columns={ 4 } className="computer only">
                    { renderLists() }
                </Grid>

                <Grid columns={ 3 } className="tablet mobile only stackable">
                    { renderLists() }
                </Grid>

            </div>
        </AuthPageWrapper>
    );
}

export default Lists;
