import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHabitInsights } from '../../../actions';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import 'react-datepicker/dist/react-datepicker.css';
import { getChartColor } from '../ChartUtils';

function HabitInsights() {

    const habitInsights = useSelector((state) => state.insights.habits);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchHabitInsights());
    }, [dispatch]);


    const habitGraphData = () => {
        const gData = [];

        habitInsights.dailyCompletedHabits.forEach((insight) => {
            const obj = {name: insight.dateString };
            insight.completed.forEach((habit) => {
                obj[habit] = 1;
            });
            gData.push(obj);
        });

        return gData;
    };

    const habitBars = () => {
        return habitInsights.habits.map((habit, index) => {
            return (
                <Bar key={'barKey' + index} dataKey={habit} stackId="a" fill={getChartColor(index)} />
            );
        });
    };

    return (
        <div style={ { 'width': '100%', 'height': '500px', 'backgroundColor': '#ffffff' } }>
            { habitInsights &&
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={500}
                    height={300}
                    data={habitGraphData()}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    { habitBars() }
                </BarChart>
            </ResponsiveContainer>
            }
        </div>
    );
}

export default HabitInsights;
