import 'regenerator-runtime/runtime';
import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions';
import { appMetric, SAVE_FEEDBACK_RESPONSE, successAlert } from '../actions';
import api from '../services/api';
import { errorWrap } from './sagaUtils';

const ROOT_URL = '';

export function* watchSaveFeedback() {
    yield takeEvery(actions.SAVE_FEEDBACK, errorWrap(saveFeedbackSaga));
}

export function* saveFeedbackSaga(action) {
    yield put(appMetric('API: Save Feedback'));
    const response = yield call(api.post, `${ ROOT_URL }/hopto/feedback`, action.values);
    if (action.callback) {
        action.callback();
    }
    const payload = response ? response.data : {};
    yield put({ type: SAVE_FEEDBACK_RESPONSE, payload });
    yield put(successAlert('Feedback successfully saved!'));
}
