import React from 'react';
import Todos from './components/todo/Todos';
import HabitForm from './components/todo/form/HabitForm';
import TodosForm from './components/todo/form/TodoForm';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './components/auth/Login';
import Welcome from './components/Welcome';
import Register from './components/auth/Register';
import ChangePassword from './components/auth/ChangePassword';
import CompleteRegistration from './components/auth/CompleteRegistration';
import StartPasswordReset from './components/auth/StartPasswordReset';
import PasswordReset from './components/auth/PasswordReset';
import Tags from './components/tag/Tags';
import TagForm from './components/tag/form/TagForm';
import Insights from './components/insights/Insights';
import GoalForm from './components/todo/form/GoalForm';
import Background from './components/background/Background';
import Preferences from './components/preferences/Preferences';
import Dashboard from './components/dashboard/Dashboard';
import ListItems from './components/list/ListItems';
import Lists from './components/list/Lists';
import ListForm from './components/list/form/ListForm';
import ListItemForm from './components/list/form/ListItemForm';

function App() {

    return (
        <BrowserRouter>
            <div>
                <Routes>
                    <Route path="/" element={ <Welcome/> }/>
                    <Route path="/register" element={ <Register/> }/>
                    <Route path="/complete-signup/:username/:code" element={ <CompleteRegistration/> }/>
                    <Route path="/login" element={ <Login/> }/>
                    <Route path="/change-password" element={ <ChangePassword/> }/>
                    <Route path="/start-password-reset" element={ <StartPasswordReset/> }/>
                    <Route path="/password-reset/:code" element={ <PasswordReset/> }/>
                    <Route path="/todos" element={ <Todos/> }/>
                    <Route path="/todos/new-todo" element={ <TodosForm/> }/>
                    <Route path="/todos/new-habit" element={ <HabitForm/> }/>
                    <Route path="/todos/new-goal" element={ <GoalForm/> }/>
                    <Route path="/lists/list" element={ <ListItems/> }/>
                    <Route path="/lists/lists" element={ <Lists/> }/>
                    <Route path="/lists/new-list" element={ <ListForm/> }/>
                    <Route path="/lists/new-list-item" element={ <ListItemForm/> }/>
                    <Route path="/tags" element={ <Tags/> }/>
                    <Route path="/todos/new-tag" element={ <TagForm/> }/>
                    <Route path="/insights" element={ <Insights/> }/>
                    <Route path="/dashboard" element={ <Dashboard/> }/>
                    <Route path="/background" element={ <Background/> }/>
                    <Route path="/preferences" element={ <Preferences/> }/>
                </Routes>
            </div>
        </BrowserRouter>
    );
}

export default App;
