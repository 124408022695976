export const REGISTER = 'REGISTER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const COMPLETE_REGISTRATION = 'COMPLETE_REGISTRATION';
export const COMPLETE_REGISTRATION_SUCCESS = 'COMPLETE_REGISTRATION_SUCCESS';
export const COMPLETE_REGISTRATION_FAIL = 'COMPLETE_REGISTRATION_FAIL';
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';
export const START_PASSWORD_RESET = 'START_PASSWORD_RESET';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';

export const register = (username, email, password, callback) => ({
    type: REGISTER,
    username,
    email,
    password,
    callback
});

export const completeRegistration = (username, code, callback) => ({
    type: COMPLETE_REGISTRATION,
    username,
    code,
    callback
});

export const login = (username, password, callback) => ({
    type: LOGIN,
    username,
    password,
    callback
});

export const logout = () => ({
    type: LOGOUT
});

export const refreshTokenSuccess = (accessToken) => ({
    type: REFRESH_TOKEN_SUCCESS,
    payload: accessToken
});

export const changePassword = (username, currentPassword, newPassword, callback) => ({
    type: CHANGE_PASSWORD,
    username,
    currentPassword,
    newPassword,
    callback
});

export const startPasswordReset = (email, callback) => ({
    type: START_PASSWORD_RESET,
    email,
    callback
});

export const passwordReset = (username, password, code, callback) => ({
    type: PASSWORD_RESET,
    username,
    password,
    code,
    callback
});
