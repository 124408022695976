import React from 'react';

const Terms = () => {

    return (

        <div>
            <p><strong>Terms of Service for www.hopskip.io</strong></p>

            <p>Welcome to www.hopskip.io! These Terms of Service (&quot;Terms&quot;) govern your use of our website and services
                (&quot;Service&quot;).
                By accessing or using the Service, you agree to be bound by these Terms. Please read them carefully
                before using the Service.</p>

            <p><strong>1. Eligibility and Access</strong></p>

            <p>You must be at least 18 years old and have the legal capacity to enter into these Terms to use the
                Service.</p>

            <p><strong>2. Account and Login</strong></p>

            <p>You may create an account with a unique username and password.
                Please keep your login credentials confidential and do not share them with anyone.
                You are responsible for all activity that occurs under your account.
                We reserve the right to terminate your account at any time and for any reason, with or without
                notice.</p>

            <p><strong>3. Free Services and Limitations</strong></p>

            <p>We offer a free tier of service with certain limitations.
                We reserve the right, at any time and without notice, to modify or discontinue the free tier of service.
                You agree that you will not use the Services in any way that exceeds the limitations of the free tier of
                service.</p>

            <p><strong>4. Acceptable Use Policy</strong></p>

            <p>You agree to use the Services only for lawful purposes and in accordance with these Terms. You agree not
                to:</p>

            <ul>
                <li>Violate any laws or regulations.</li>
                <li>Infringe on the rights of others, including their intellectual property rights.</li>
                <li>Transmit any harmful content, such as viruses, malware, or spyware.</li>
                <li>Post or transmit any content that is harmful, unethical, racist, sexist, toxic, dangerous, or
                    illegal.
                </li>
                <li>Impersonate any person or entity.</li>
                <li>Interfere with or disrupt the Services.</li>
                <li>Use the Services for commercial purposes without our prior written consent.</li>
            </ul>

            <p><strong>5. User Content</strong></p>

            <p>You are responsible for any content that you post or transmit through the Services.
                We do not endorse any user content and we are not responsible for any content posted or transmitted by
                you or other users.
                We reserve the right, at any time and without notice, to remove or disable access to any user content
                that we deem to be in violation of these Terms.</p>

            <p><strong>6. User Removal and Termination</strong></p>

            <p>We reserve the right to terminate your access to the Service or remove your User Content at any time, for
                any reason, without notice.
                This includes, but is not limited to, if we believe you have violated these Terms or engaged in any
                harmful or illegal activity.</p>

            <p><strong>7. Disclaimers and Limitations of Liability</strong></p>

            <p><strong>THE SERVICES ARE PROVIDED &quot;AS IS&quot; AND WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED,
                INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE, AND NON-INFRINGEMENT.
                WE ARE NOT LIABLE FOR ANY DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE USE OF THE SERVICES,
                INCLUDING, BUT NOT LIMITED TO, DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, AND PUNITIVE DAMAGES.
                WE DO NOT WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED, ERROR-FREE, OR SECURE.</strong></p>

            <p><strong>8. Limitation of Liability</strong></p>

            <p>To the maximum extent permitted by law, we will not be liable for any damages arising out of or in
                connection with your use of the Service.
                This includes, but is not limited to, direct, indirect, incidental, consequential, and punitive
                damages.</p>

            <p><strong>9. Indemnification:</strong></p>

            <p>You agree to indemnify and hold harmless us and our affiliates, officers, agents, partners, and employees
                from any and all claims, losses, damages, liabilities, costs, and expenses (including attorneys&apos; fees)
                arising from your use of our website or services or your violation of these Terms.</p>

            <p><strong>10. Governing Law and Dispute Resolution:</strong></p>

            <p>These Terms shall be governed by and construed in accordance with the laws of the State of Illinois,
                without regard to its conflict of laws provisions.
                Any dispute arising out of or relating to these Terms shall be resolved through binding arbitration in
                accordance with the rules of the American Arbitration Association.</p>

            <p><strong>11. Entire Agreement</strong></p>

            <p>These Terms constitute the entire agreement between you and us with respect to your use of the
                Service.</p>

            <p><strong>12. Contact Us</strong></p>

            <p>If you have any questions about these Terms, please contact us at hopskip.feedback@gmail.com.</p>

        </div>
    );
};

export default Terms;
