import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Header } from 'semantic-ui-react';
import Todo from '../todo/Todo';
import { CardTypes, orderedItemsOfType } from '../../utils/todoUtils';

import styles from '../todo/Todos.module.css';
import { getDaysAgoDayString, getTodayDayString } from '../../utils/dateUtils';

function HabitsDashboard() {

    const map = useSelector((state) => state.todos.map);
    const sortOrder = useSelector((state) => state?.todos?.sortOrder?.order ?? []);
    const todos = useSelector((state) => state.todos.list ? state.todos.list : null);
    useSelector((state) => state.tags.list ? state.tags.list : null);

    const KEEP_IT_UP = 'KEEP_IT_UP';
    const WORK_ON = 'WORK_ON';

    const visibleCardsOfType = (type, sortOrder) => {
        if (type === KEEP_IT_UP) {
            return orderedItemsOfType(todos, CardTypes.habit, map, sortOrder)
                .filter(item => item.repeatsType === 'daily' && doingGoodOnHabit(item));
        } else {
            return orderedItemsOfType(todos, CardTypes.habit, map, sortOrder)
                .filter(item => item.repeatsType === 'daily' && !doingGoodOnHabit(item));
        }
    };

    const doingGoodOnHabit = (habit) => {
        if (!habit.completedOn) return false;

        const startDate = getDaysAgoDayString(5);
        const todayDate = getTodayDayString();

        if (todayDate === habit.completedOn[habit.completedOn.length -1])
            return true;

        let completed = 0;
        for (let x = habit.completedOn.length; x > 0 && x > habit.completedOn.length - 5; x--) {
            if (habit.completedOn[x] >= startDate) {
                completed = completed + 1;
            }
        }

        return completed >= 4;
    };

    const showCardsOfType = (type) => {
        return visibleCardsOfType(type, sortOrder).length > 0;
    };

    const renderCardSectionOfType = (type) => {
        return (
            <React.Fragment key={type}>
                { renderCardSectionForDevice(type, 4, 'computer only')}
                { renderCardSectionForDevice(type, 3, 'tablet only')}
                { renderCardSectionForDevice(type, 1, 'mobile only')}
            </React.Fragment>
        );
    };

    const renderCardSectionForDevice = (type, columns, device) => {
        if (!showCardsOfType(type)) return;

        return (
            <>
                <Grid columns={ columns } className={ device }>
                    { renderCardSectionHeader(type) }
                </Grid>
                <Grid columns={ columns } className={ device }>
                    { renderCardOfType(type) }
                </Grid>
            </>
        );
    };

    const renderCardSectionHeader = (type) => {
        return (
            <Grid.Column>
                <div className={ styles.todosSectionTitle }>
                    <Header as='h3' className={styles.todoSectionHeaderLeft}>
                        Daily Habits - { type === KEEP_IT_UP ? 'Keep It Up!' : 'Work On It!' }
                    </Header>
                </div>
            </Grid.Column>);
    };

    const renderCardOfType = (type) => {
        return visibleCardsOfType(type, sortOrder)
            .map((todo) => {
                return (
                    <Grid.Column key={ todo.id }>
                        <Todo todo={ todo }/>
                    </Grid.Column>
                );
            });
    };

    return (
        <div>
            { renderCardSectionOfType(KEEP_IT_UP) }
            { renderCardSectionOfType(WORK_ON) }
        </div>
    );
}

export default HabitsDashboard;
