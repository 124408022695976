import {
    CREATE_LIST_ITEM_RESPONSE,
    CREATE_LIST_RESPONSE,
    DELETE_LIST_ITEM_RESPONSE,
    DELETE_LIST_RESPONSE,
    FETCH_LIST_RESPONSE,
    FETCH_LISTS_RESPONSE,
    RESET_STATE,
    UPDATE_LIST_ITEM_RESPONSE,
    UPDATE_LIST_RESPONSE
} from '../actions';

function listsReducer(state = { }, action) {
    switch (action.type) {
    case FETCH_LISTS_RESPONSE:
        return { ...state,
            lists: action.payload
        };
    case FETCH_LIST_RESPONSE:
        return { ...state,
            listItems: action.payload
        };
    case CREATE_LIST_RESPONSE:
        return { ...state,
            lists: [ ...state.lists, action.payload]
        };
    case DELETE_LIST_RESPONSE: {
        return {
            ...state,
            lists: state.lists.filter(list => {
                return list.id !== action.payload.id;
            })
        };
    }
    case UPDATE_LIST_RESPONSE: {
        // Loop on a list
        let newList = state.lists.map(function (item) {
            return item.id === action.payload.id ? action.payload : item;
        });
        return { ...state,
            lists: newList
        };
    }
    case CREATE_LIST_ITEM_RESPONSE:
        return { ...state,
            listItems: [ ...state.listItems, action.payload]
        };
    case DELETE_LIST_ITEM_RESPONSE: {
        return {
            ...state,
            listItems: state.listItems.filter(listItem => {
                return listItem.id !== action.payload.id;
            })
        };
    }
    case UPDATE_LIST_ITEM_RESPONSE: {
        // Loop on a list
        let newList = state.listItems.map(function (item) {
            return item.id === action.payload.id ? action.payload : item;
        });
        return { ...state,
            listItems: newList
        };
    }
    case RESET_STATE:
        return { };
    default:
        return state;
    }
}

export default listsReducer;
