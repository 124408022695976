import { FETCH_USER_PREFS_RESPONSE, RESET_STATE, SAVE_USER_PREFS_RESPONSE } from '../actions';

function userPrefsReducer(state = { preferences: null }, action) {
    switch (action.type) {
    case FETCH_USER_PREFS_RESPONSE:
        return {...state, preferences: action.payload };
    case SAVE_USER_PREFS_RESPONSE:
        return {...state, preferences: action.payload ? action.payload : {} };
    case RESET_STATE:
        return { preferences: null };
    default:
        return state;
    }
}

export default userPrefsReducer;
