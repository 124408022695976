import dayjs from 'dayjs';

export function getTodayDayString() {
    return dayjs().format('YYYYMMDD');
}

export function getDayString(date) {
    return dayjs(date).format('YYYYMMDD');
}

export function getDaysAgoDayString(daysAgo) {
    return dayjs().subtract(daysAgo, 'day').format('YYYYMMDD');
}

export function getDaysAheadDayString(daysAgo) {
    return dayjs().add(daysAgo, 'day').format('YYYYMMDD');
}

export function getMonthsAgoString(monthsAgo) {
    return dayjs().subtract(monthsAgo, 'month').format('YYYYMMDD');
}

export function getDateFromDayString(dayString) {
    return dayjs(dayString, 'YYYYMMDD').toDate();
}

export function getDateDisplayStringFromDayString(dayString) {
    return dayjs(dayString, 'YYYYMMDD').format('MM/DD/YYYY');
}
