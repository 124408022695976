export const FETCH_TODOS = 'FETCH_TODOS';
export const FETCH_TODOS_RESPONSE = 'FETCH_TODOS_RESPONSE';
export const FETCH_TODO = 'FETCH_TODO';
export const FETCH_TODO_RESPONSE = 'FETCH_TODO_RESPONSE';
export const CREATE_TODO = 'CREATE_TODO';
export const UPDATE_TODO = 'UPDATE_TODO';
export const CREATE_TODO_RESPONSE = 'CREATE_TODO_RESPONSE';
export const DELETE_TODO = 'DELETE_TODO';
export const DELETE_TODO_RESPONSE = 'DELETE_TODO_RESPONSE';
export const COMPLETE_TODO = 'COMPLETE_TODO';
export const ELECT_FAIL_TODO = 'ELECT_FAIL_TODO';
export const INCREMENT_COUNT_TODO = 'INCREMENT_COUNT_TODO';
export const DECREMENT_COUNT_TODO = 'DECREMENT_COUNT_TODO';
export const SET_COUNT_TODO = 'SET_COUNT_TODO';
export const SET_GOAL_PROGRESS_TODO = 'SET_GOAL_PROGRESS_TODO';
export const UPDATE_TODO_RESPONSE = 'UPDATE_TODO_RESPONSE';

export const FETCH_SORT_ORDER = 'FETCH_SORT_ORDER';
export const FETCH_SORT_ORDER_RESPONSE = 'FETCH_SORT_ORDER_RESPONSE';
export const SAVE_SORT_ORDER = 'SAVE_SORT_ORDER';
export const SAVE_SORT_ORDER_RESPONSE = 'SAVE_SORT_ORDER_RESPONSE';

export function fetchTodos() {
    return {
        type: FETCH_TODOS
    };
}

export function fetchTodo(id) {
    return {
        type: FETCH_TODO,
        id: id,
    };
}

export function createTodo(values, callback) {
    return {
        type: CREATE_TODO,
        values,
        callback
    };
}

export function updateTodo(values, callback) {
    return {
        type: UPDATE_TODO,
        values,
        callback
    };
}

export function deleteTodo(todo, callback) {
    return {
        type: DELETE_TODO,
        todo,
        callback
    };
}

export function completeTodo(todo) {
    return {
        type: COMPLETE_TODO,
        todo
    };
}

export function electFailTodo(todo) {
    return {
        type: ELECT_FAIL_TODO,
        todo
    };
}

export function incrementCountTodo(todo) {
    return {
        type: INCREMENT_COUNT_TODO,
        todo
    };
}

export function decrementCountTodo(todo) {
    return {
        type: DECREMENT_COUNT_TODO,
        todo
    };
}

export function setCountTodo(id, count) {
    return {
        type: SET_COUNT_TODO,
        id,
        count
    };
}

export function setGoalProgressTodo(id, count) {
    return {
        type: SET_GOAL_PROGRESS_TODO,
        id,
        count
    };
}

export function fetchSortOrder() {
    return {
        type: FETCH_SORT_ORDER
    };
}

export function saveSortOrder(values) {
    return {
        type: SAVE_SORT_ORDER,
        values
    };
}
