import './ImageList.css';
import React from 'react';
import ImageCard from './ImageCard';
import PropTypes from 'prop-types';

const ImageList = (props) => {
    const images = props.images.map(image => {
        return <ImageCard key={image.id} image={image} />;
    });

    return (
        <div className="image-list">{images}</div>
    );
};

ImageList.propTypes = {
    images: PropTypes.array
};

export default ImageList;
