import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { createList, deleteList } from '../../../actions';
import { Button, Form } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import AuthPageWrapper from '../../wrapper/AuthPageWrapper';

function ListForm() {

    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const user = useSelector((state) => state.auth.user ? state.auth.user : null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let defaultValues = {};
        defaultValues.user = user.username;
        if (location.state && location.state.list) {
            const list = location.state.list;
            defaultValues.id = list.id;
            defaultValues.name = list.name;
            defaultValues.description = list.description;
            defaultValues.template = list.template;
            defaultValues.defaultDisplay = list.defaultDisplay;
        }
        reset({ ...defaultValues });
    }, [user.username, location.state, reset]);

    const onDeleteClick = () => {
        dispatch(deleteList(location.state.list.id, () => {navigate('/lists/lists');}));
    };

    const onSubmit = (data) => {
        dispatch(createList(data, () => {
            navigate('/lists/lists');
        }));
        //alert(JSON.stringify(data, null, 2))
    };

    return (
        <AuthPageWrapper>
            <Form onSubmit={ handleSubmit(onSubmit) }>
                <Form.Field className={ `${ errors.name ? 'error' : '' }` }>
                    <label htmlFor="name">Name</label>
                    <input placeholder="Name" { ...register('name', { required: true }) } />
                    { errors.name &&
                        <div className="ui pointing above prompt label" role="alert" aria-atomic="true">Please enter a name</div> }
                </Form.Field>

                <Form.Field>
                    <label htmlFor="description">Description</label>
                    <input placeholder="Description" { ...register('description', { required: false }) } />
                </Form.Field>

                <Form.Field>
                    <label htmlFor="defaultDisplay">Default Display</label>
                    <select className="ui fluid dropdown" { ...register('defaultDisplay', { required: false }) }>
                        <option value="">Default Display</option>
                        <option value="cards">Cards</option>
                        <option value="items">List Items</option>
                    </select>
                </Form.Field>

                <Form.Field>
                    <label htmlFor="template">Template</label>
                    <textarea placeholder="Template" {...register('template', {required: false})} />
                </Form.Field>

                {location.state && location.state.list &&
                    <Button type="button"
                        className="negative"
                        onClick={onDeleteClick}>
                        Delete List
                    </Button>
                }

                <Button type="button"
                    className="secondary"
                    onClick={(e) => {
                        e.preventDefault();
                        navigate('/lists/lists');
                    }}>
                    Cancel
                </Button>
                <Button className="primary" type="submit">Submit</Button>
            </Form>
        </AuthPageWrapper>
    );

}

export default ListForm;
