import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { createTodo, deleteTodo, updateTodo } from '../../../actions';
import { Button, Form } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import AuthPageWrapper from '../../wrapper/AuthPageWrapper';
import FormTags from './FormTags';
import styles from './Form.module.css';
import DatePicker from 'react-datepicker';
import { getDateFromDayString, getDayString } from '../../../utils/dateUtils';

function GoalForm() {

    const [selectedTags, setSelectedTags] = useState([]);
    const [dueDate, setDueDate] = useState(new Date());
    const { register, watch, handleSubmit, reset, formState: { errors } } = useForm();
    const user = useSelector((state) => state?.auth?.user ?? null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const todo = location?.state?.todo;

    useEffect(() => {
        let defaultValues = {};
        defaultValues.user = user.username;
        if (todo) {
            defaultValues.id = todo.id;
            defaultValues.description = todo.description;
            defaultValues.details = todo.details;
            defaultValues.countGoal = todo.countGoal;
            defaultValues.goalDecimals = todo.goalDecimals;
            setSelectedTags(todo.tags);
            if (todo.dueDate) {
                defaultValues.showDueDate = true;
                setDueDate(getDateFromDayString(todo.dueDate));
            }
        }
        reset({ ...defaultValues });
    }, [user.username, todo, reset]);

    const onDeleteClick = () => {
        dispatch(deleteTodo(todo, () => {navigate('/todos');}));
    };

    const onSubmit = (data) => {
        data['tags'] = selectedTags;

        if (data['showDueDate'] && dueDate) {
            data['dueDate'] = getDayString(dueDate);
        }

        data['trackingType'] = 'number';
        if (todo) {
            data['goalOn'] = todo.goalOn;
            dispatch(updateTodo(data, () => {
                navigate('/todos');
            }));
        } else {
            dispatch(createTodo(data, () => {
                navigate('/todos');
            }));
        }
        //alert(JSON.stringify(data, null, 2))
    };

    return (
        <AuthPageWrapper>
            <Form onSubmit={ handleSubmit(onSubmit) }>
                <Form.Field className={ `${ errors.description ? 'error' : '' }` }>
                    <label>Description</label>
                    <input autoFocus placeholder="Description" { ...register('description', { required: true }) } />
                    { errors.description &&
                        <div className="ui pointing above prompt label" role="alert" aria-atomic="true">Please enter your
                            last name</div> }
                </Form.Field>
                <Form.Field>
                    <label>Details</label>
                    <input placeholder="Details" { ...register('details') } />
                </Form.Field>

                <Form.Field className={ `${ errors.countGoal ? 'error' : '' }` }>
                    <label>Count Goal</label>
                    <input type="number"
                        placeholder="Numerical Goal" { ...register('countGoal', { required: true }) } />
                    { errors.countGoal &&
                        <div className="ui pointing above prompt label" role="alert" aria-atomic="true">Please enter a
                            count goal</div> }
                </Form.Field>

                <Form.Field className={ `${ errors.goalDecimals ? 'error' : '' }` }>
                    <label>Decimal Places Allowed</label>
                    <input type="number"
                        placeholder="Decimals Allowed" { ...register('goalDecimals', { required: false }) } />
                </Form.Field>

                <Form.Field className={styles.toggle}>
                    <div className="ui checkbox toggle">
                        <input type="checkbox" id="show-due-date-id" tabIndex="1" { ...register('showDueDate') } />
                        <label>Include a Due Date</label>
                    </div>
                </Form.Field>

                { watch('showDueDate') &&
                    <Form.Field>
                        <DatePicker
                            className={styles.datepicker}
                            selected={dueDate}
                            onChange={(date) => setDueDate(date)} />
                    </Form.Field>
                }

                <FormTags tags={todo ? todo.tags : []} setSelectedTags={setSelectedTags}/>

                {todo &&
                    <Button type="button"
                        className="negative"
                        onClick={onDeleteClick}>
                        Delete Todo
                    </Button>
                }

                <Button type="button"
                    className="secondary"
                    onClick={ (e) => {
                        e.preventDefault();
                        navigate('/todos');
                    } }>
                    Cancel
                </Button>
                <Button className="primary" type="submit">Submit</Button>
            </Form>
        </AuthPageWrapper>
    );

}

export default GoalForm;
