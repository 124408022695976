import {
    FETCH_COMPLETED_INSIGHTS_RESPONSE,
    FETCH_HABIT_INSIGHTS_RESPONSE,
    FETCH_TODO_BY_DATE_INSIGHTS_RESPONSE, FETCH_TODO_BY_TAG_INSIGHTS_RESPONSE,
    RESET_STATE
} from '../actions';

function insightsReducer(state = {}, action) {
    switch (action.type) {
    case FETCH_TODO_BY_DATE_INSIGHTS_RESPONSE:
        return { ...state, todosByDate: action.payload };
    case FETCH_TODO_BY_TAG_INSIGHTS_RESPONSE:
        return { ...state, todosByTag: action.payload };
    case FETCH_HABIT_INSIGHTS_RESPONSE:
        return { ...state, habits: action.payload };
    case FETCH_COMPLETED_INSIGHTS_RESPONSE:
        return { ...state, completed: action.payload };
    case RESET_STATE:
        return {};
    default:
        return state;
    }
}

export default insightsReducer;
