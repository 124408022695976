import { combineReducers } from 'redux';
import AlertsReducer from './alertsReducer';
import TodosReducer from './todosReducer';
import TagsReducer from './tagsReducer';
import AuthReducer from './authReducer';
import InsightsReducer from './insightsReducer';
import uxReducer from './uxReducer';
import UserPrefsReducer from './userPrefsReducer';
import ListsReducer from './listsReducer';

const rootReducer = combineReducers({
    alerts: AlertsReducer,
    todos: TodosReducer,
    lists: ListsReducer,
    tags: TagsReducer,
    auth: AuthReducer,
    insights: InsightsReducer,
    ux: uxReducer,
    user: UserPrefsReducer
});

export default rootReducer;
