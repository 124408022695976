import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container } from 'semantic-ui-react';
import Alerts from './Alerts';

function Welcome() {

    const navigate = useNavigate();

    return (

        <div style={ {
            'minHeight': '100vh',
            'backgroundSize': 'cover',
            'backgroundRepeat': 'no-repeat',
            'backgroundPosition': 'center center',
            'backgroundImage': 'url(/welcome-bg2.jpg)',
            'backgroundAttachment': 'fixed'
        } }>
            <Container>

                <div className="ui vertical masthead center aligned segment">

                    <div className="ui large secondary pointing menu">
                        <a href="/" className="toc item">
                            <i className="sidebar icon"></i>
                            HopSkip
                        </a>
                        <div className="right item">
                            <Button type="button"
                                onClick={ (e) => {
                                    e.preventDefault();
                                    navigate('/login');
                                } }>
                                Login
                            </Button>
                            &nbsp;
                            <Button type="button"
                                onClick={ (e) => {
                                    e.preventDefault();
                                    navigate('/register');
                                } }>
                                Register
                            </Button>
                        </div>
                    </div>

                    <div className="ui text container" style={{marginTop: '150px'}}>
                        <h1 className="ui header" style={{fontSize: '4em'}}>
                            HopSkip
                        </h1>
                        <h2 className="ui header">Embrace the Next You</h2>
                    </div>

                </div>

                <Alerts/>

            </Container>

        </div>
    );
}

export default Welcome;
