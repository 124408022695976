import dayjs from 'dayjs';

export const CardTypes = {
    todo: 'todo',
    habit: 'habit',
    goal: 'goal'
};

export function isType(todo, type) {
    if (type === CardTypes.todo) {
        return isTypeTodo(todo);
    } else if (type === CardTypes.habit) {
        return isTypeHabit(todo);
    } else if (type === CardTypes.goal) {
        return isTypeGoal(todo);
    } else {
        return false;
    }
}

export function getType(todo) {
    if (isTypeTodo(todo)) {
        return CardTypes.todo;
    } else if (isTypeHabit(todo)) {
        return CardTypes.habit;
    } else if (isTypeGoal(todo)){
        return CardTypes.goal;
    } else {
        throw 'Item type cannot be determined';
    }
}

export function isTypeTodo(todo) {
    return !todo.repeatsType && todo.trackingType !== 'number';
}

export function isTypeGoal(todo) {
    return todo.trackingType === 'number';
}

export function isTypeHabit(todo) {
    return !!todo.repeatsType;
}

export const todoRepeats = (todo) => {
    return todo.repeatsType && todo.repeatsType.length > 0;
};

export const todoTracking = (todo) => {
    return todo.trackingType && todo.trackingType.length > 0;
};

const isGoalReached = (todo) => {
    const entries = Object.values(todo?.goalOn ?? {});
    if (false === isType(todo, CardTypes.goal)) return false;
    if (entries.length === 0) return false;

    if (entries[0] < todo.countGoal && entries[entries.length - 1] >= todo.countGoal) return true;
    return entries[0] > todo.countGoal && entries[entries.length - 1] <= todo.countGoal;
};

export function isCompleted(todo) {
    const dateString = dayjs().format('YYYYMMDD');
    const weekString = dayjs().startOf('week').format('YYYYMMDD');
    const monthString = dayjs().format('YYYYMM');
    return isGoalReached(todo)
        || (!todoRepeats(todo) && todoTracking(todo) && todo.completedOn)
        || (todoRepeats(todo) && todo.repeatsType === 'daily' && todo.completedOn && todo.completedOn[todo.completedOn.length - 1] >= dateString)
        || (todoRepeats(todo) && todo.repeatsType === 'daily' && todo.countGoal && countForDay(todo) >= todo.countGoal)
        || (todoRepeats(todo) && todo.repeatsType === 'weekly' && todo.completedOn && todo.completedOn[todo.completedOn.length - 1] >= weekString)
        || (todoRepeats(todo) && todo.repeatsType === 'weekly' && todo.countGoal && countForWeek(todo) >= todo.countGoal)
        || (todoRepeats(todo) && todo.repeatsType === 'monthly' && todo.completedOn && todo.completedOn[todo.completedOn.length - 1] >= monthString)
        || (todoRepeats(todo) && todo.repeatsType === 'monthly' && todo.countGoal && countForMonth(todo) >= todo.countGoal);
}

export function isFailed(todo) {
    const dateString = dayjs().format('YYYYMMDD');
    const weekString = dayjs().startOf('week').format('YYYYMMDD');
    const monthString = dayjs().format('YYYYMM');
    return (todoRepeats(todo) && todo.repeatsType === 'daily' && todo.electedFailOn && todo.electedFailOn >= dateString)
        || (todoRepeats(todo) && todo.repeatsType === 'weekly' && todo.electedFailOn && todo.electedFailOn >= weekString)
        || (todoRepeats(todo) && todo.repeatsType === 'monthly' && todo.electedFailOn && todo.electedFailOn >= monthString);
}

export function isDone(todo) {
    if (!todo) return false;
    return isCompleted(todo) || isFailed(todo);
}

export function todoCount(todo) {
    if (!todo.repeatsType || !todo.countOn) {
        return 0;
    }

    if (todo.repeatsType && todo.repeatsType === 'daily') {
        return countForDay(todo);
    } else if (todo.repeatsType && todo.repeatsType === 'weekly') {
        return countForWeek(todo);
    } else if (todo.repeatsType && todo.repeatsType === 'monthly') {
        return countForMonth(todo);
    } else {
        return 0;
    }
}

export function countForDay(todo) {
    const dateString = dayjs().format('YYYYMMDD');
    return todo.countOn && todo.countOn[dateString] ? todo.countOn[dateString] : 0;
}

export function countForWeek(todo) {
    const weekString = dayjs().startOf('week').format('YYYYMMDD');
    return todo.countOn && todo.countOn[weekString] ? todo.countOn[weekString] : 0;
}

export function countForMonth(todo) {
    const dateString = dayjs().format('YYYYMM');
    return todo.countOn && todo.countOn[dateString] ? todo.countOn[dateString] : 0;
}

export function completeTodo(todo) {
    let dateString = dayjs().format('YYYYMMDD');
    if (todo.repeatsType === 'weekly') {
        dateString = dayjs().startOf('week').format('YYYYMMDD');
    } else if (todo.repeatsType === 'monthly') {
        dateString = dayjs().format('YYYYMM');
    }

    if (todo.completedOn) {
        todo.completedOn.push(dateString);
    } else {
        todo.completedOn = [dateString];
    }

    return todo;
}

export function electFailTodo(todo) {
    let dateString = dayjs().format('YYYYMMDD');
    todo.electedFailOn = dateString;

    return todo;
}

export function incrementTodo(todo) {
    let dateString = dayjs().format('YYYYMMDD');
    if (todo.repeatsType === 'weekly') {
        dateString = dayjs().startOf('week').format('YYYYMMDD');
    } else if (todo.repeatsType === 'monthly') {
        dateString = dayjs().format('YYYYMM');
    }

    if (!todo.countOn) { todo.countOn = {}; }
    if (todo.countOn[dateString]) {
        todo.countOn[dateString] = todo.countOn[dateString] + 1;
    } else {
        todo.countOn[dateString] = 1;
    }

    return todo;
}

export function decrementTodo(todo) {
    let dateString = dayjs().format('YYYYMMDD');
    if (todo.repeatsType === 'weekly') {
        dateString = dayjs().startOf('week').format('YYYYMMDD');
    } else if (todo.repeatsType === 'monthly') {
        dateString = dayjs().format('YYYYMM');
    }

    if (!todo.countOn) { todo.countOn = {}; }
    if (todo.countOn[dateString]) {
        todo.countOn[dateString] = todo.countOn[dateString] - 1;
    } else {
        todo.countOn[dateString] = -1;
    }

    return todo;
}

// Order by sortOrder not completed, then not completed, then completed
export function orderedItemsOfType(items, itemType, itemMap, itemSortOrder) {
    const ordered = [];
    const orderedMap = {};
    if (itemSortOrder) {
        itemSortOrder.forEach(id => {
            if (itemMap[id] &&
                isType(itemMap[id], itemType) &&
                !isDone(itemMap[id])
            ) {
                ordered.push(itemMap[id]);
                orderedMap[id] = id;
            }
        });
    }

    const done = [];
    items.forEach((item) => {
        if (isType(item, itemType) &&
            !orderedMap[item.id]
        ) {
            if (!isDone(item)) {
                ordered.push(item);
            } else {
                done.push(item);
            }
        }
    });

    return [...ordered, ...done];
}
