import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Form, Grid, Icon } from 'semantic-ui-react';
import {setSelectedList, setSelectedListView} from '../../actions';

function ListHeader() {

    const lists = useSelector((state) => state.lists.lists ? state.lists.lists : null);
    const listSelectedList = useSelector(state => state.ux.listSelectedList);
    const listSelectedView = useSelector(state => state.ux.listSelectedView);

    const dispatch = useDispatch();

    const listOptions = lists ? lists.map(list => {
        return { key: list.name, text: list.name, value: list };
    }) : [];

    const viewOptions = [
        { key: 'cards', text: 'Cards', value: 'cards' },
        { key: 'items', text: 'Items', value: 'items' }
    ];

    useEffect(() => {
        if (lists?.length > 0 && !listSelectedList) {
            dispatch(setSelectedList(lists[0]));
            dispatch(setSelectedListView(lists[0].defaultDisplay));
        }
    }, [lists]);

    const changeSelectedList = (event, { value }) => {
        dispatch(setSelectedList(value));
        dispatch(setSelectedListView(value.defaultDisplay));
    };

    const changeSelectedView = (event, { value }) => {
        dispatch(setSelectedListView(value));
    };

    const renderListSelect = () => {
        return (
            <Form.Select
                style={{width: '100%'}}
                selection
                options={ listOptions }
                value={ listSelectedList }
                placeholder='Card Type'
                onChange={ changeSelectedList }/>);
    };

    const renderViewSelect = () => {
        return (
            <Form.Select
                style={{width: '100%'}}
                selection
                options={ viewOptions }
                value={ listSelectedView }
                placeholder='List View'
                onChange={ changeSelectedView }/>);
    };

    return (
        <>
            <Grid columns={ 3 } className="stackable">
                <Grid.Column width={ 11 } className='top aligned mobile hidden'>
                    <Form>
                        <Form.Group widths='equal'>
                            { renderListSelect() }
                            { renderViewSelect() }
                        </Form.Group>
                    </Form>
                </Grid.Column>

                <Grid.Column width={ 5 } floated='right' className='left aligned mobile hidden'>
                    <Form style={ { 'float': 'right' } }>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <Button.Group>
                                    <Link to={listSelectedList ? '/lists/new-list-item' : '#' } state={{ list: listSelectedList }}>
                                        <Button animated='vertical' disabled={!listSelectedList}>
                                            <Button.Content hidden>Item</Button.Content>
                                            <Button.Content visible><Icon name='plus circle'/></Button.Content>
                                        </Button>
                                    </Link>
                                    <Button.Or/>
                                    <Link to="/lists/new-list">
                                        <Button animated='vertical'>
                                            <Button.Content hidden>List</Button.Content>
                                            <Button.Content visible><Icon name='cube'/></Button.Content>
                                        </Button>
                                    </Link>
                                    <Button.Or/>
                                    <Link to="/lists/lists">
                                        <Button animated='vertical'>
                                            <Button.Content hidden>Lists</Button.Content>
                                            <Button.Content visible><Icon name='cubes'/></Button.Content>
                                        </Button>
                                    </Link>
                                </Button.Group>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Grid.Column>
            </Grid>

            <Grid columns={ 2 } className='mobile only'>
                <Grid.Row>
                    <Grid.Column>
                        <Button.Group>
                            <Link to={listSelectedList ? '/lists/new-list-item' : '#' } state={{ list: listSelectedList }}>
                                <Button animated='vertical' disabled={!listSelectedList}>
                                    <Button.Content hidden>Item</Button.Content>
                                    <Button.Content visible><Icon name='plus circle'/></Button.Content>
                                </Button>
                            </Link>
                            <Button.Or/>
                            <Link to="/lists/new-list">
                                <Button animated='vertical'>
                                    <Button.Content hidden>List</Button.Content>
                                    <Button.Content visible><Icon name='cube'/></Button.Content>
                                </Button>
                            </Link>
                            <Button.Or/>
                            <Link to="/lists/lists">
                                <Button animated='vertical'>
                                    <Button.Content hidden>Lists</Button.Content>
                                    <Button.Content visible><Icon name='cubes'/></Button.Content>
                                </Button>
                            </Link>
                        </Button.Group>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Form>
                            <Form.Field>
                                { renderListSelect() }
                            </Form.Field>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
}

export default ListHeader;
