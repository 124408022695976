import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteListItem } from '../../actions';
import { Button, Card, Icon } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';

import mdStyle from '../style.module.css';

function ListCard(props) {

    const preferences = useSelector(state => state.user.preferences);

    const { listItem } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isHovering, setIsHovering] = useState(false);

    const onDeleteClick = (listItem) => {
        dispatch(deleteListItem(listItem));
    };

    const handleEdit = () => {
        navigate('/lists/new-list-item', { state: { listItem: listItem } });
    };

    if (preferences === null) return;

    const cardTitleHex = preferences.cardTitleHex ? preferences.cardTitleHex : '#546e7a';
    const cardBackgroundRgba = preferences.cardBackgroundRgba ? preferences.cardBackgroundRgba : 'rgba(255,255,255,1.0)';

    return (
        <Card fluid
            onMouseOver={ () => setIsHovering(true) }
            onMouseOut={ () => setIsHovering(false) }
            style={ { backgroundColor: cardBackgroundRgba } }>
            <Card.Content>
                <Card.Header style={ { color: cardTitleHex } }>
                    { isHovering &&
                        <div style={ { float: 'right', marginRight: '-1rem', marginTop: '-.5rem' } }>
                            <Icon circular color='grey' link name='pencil alternate' onClick={ handleEdit }/>
                        </div>
                    }
                    { listItem.name }

                </Card.Header>

                <Card.Description style={{color: cardTitleHex}}>
                    <div className={mdStyle.markdown}>
                        <Markdown>{listItem.description}</Markdown>
                    </div>
                </Card.Description>
            </Card.Content>
            <Card.Content extra className='right aligned'>
                <div>
                    <Button onClick={ () => onDeleteClick(listItem) }>Delete</Button>
                </div>
            </Card.Content>
        </Card>
    );
}

ListCard.propTypes = {
    listItem: PropTypes.object
};

export default ListCard;
