import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import rootSaga from './sagas';
import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import reducers from './reducers';
import 'semantic-ui-css/semantic.min.css';
import setupInterceptors from './services/setupInterceptors';

const el = document.getElementById('root');
const root = ReactDOM.createRoot(el);

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: reducers,
    middleware: [sagaMiddleware],
});

setupInterceptors(store);

sagaMiddleware.run(rootSaga);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);
