import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setTodoTagFilters, setTodoTypeFilters, setTodoView } from '../../actions';
import { Button, Form, Grid, Icon } from 'semantic-ui-react';

function TodosHeader() {

    const tags = useSelector((state) => state.tags.list ? state.tags.list : null);
    const todoTypeFilters = useSelector(state => state.ux.todoTypeFilters);
    const todoTagFilters = useSelector(state => state.ux.todoTagFilters);
    const todoView = useSelector(state => state.ux.todoView);
    const dispatch = useDispatch();

    const cartTypOptions = [
        { key: 'todo', text: 'Todo', value: 'todo' },
        { key: 'habit', text: 'Habit', value: 'habit' },
        { key: 'goal', text: 'Goal', value: 'goal' },
        { key: 'daily', text: 'Daily', value: 'daily' },
        { key: 'weekly', text: 'Weekly', value: 'weekly' },
        { key: 'monthly', text: 'Monthly', value: 'monthly' },
        { key: 'completed', text: 'Completed', value: 'completed' },
    ];

    const tagOptions = tags ? tags.map(tag => {
        return { key: tag.tag, text: tag.tag, value: tag.tag };
    }) : {};

    const filterByCardType = (event, { value }) => {
        dispatch(setTodoTypeFilters(value));
    };

    const filterByTag = (event, { value }) => {
        dispatch(setTodoTagFilters(value));
    };

    const changeTodoView = view => {
        dispatch(setTodoView(view));
    };

    const renderTypeSelect = () => {
        return (
            <Form.Select
                style={{width: '100%'}}
                clearable
                multiple
                selection
                options={ cartTypOptions }
                value={ todoTypeFilters }
                placeholder='Card Type'
                onChange={ filterByCardType }/>);
    };

    const renderTagSelect = () => {
        return (
            <Form.Select
                clearable
                multiple
                selection
                options={ tagOptions }
                value={ todoTagFilters }
                placeholder='Tag'
                onChange={ filterByTag }/>);
    };

    return (
        <>
            <Grid columns={ 3 } className="stackable">
                <Grid.Column width={ 9 } className='mobile hidden top aligned'>
                    <Form>
                        <Form.Group widths='equal'>
                            { renderTypeSelect() }
                            { renderTagSelect() }
                        </Form.Group>
                    </Form>
                </Grid.Column>

                <Grid.Column width={ 2 } className='mobile hidden top aligned'>
                    <Form>
                        <Button.Group>
                            <Button icon active={todoView === 'rows' ? true : false}
                                onClick={() => changeTodoView('rows')}
                            >
                                <Icon name='arrows alternate horizontal'/>
                            </Button>
                            <Button icon active={todoView === 'columns' ? true : false}
                                onClick={() => changeTodoView('columns')}
                            >
                                <Icon name='arrows alternate vertical'/>
                            </Button>
                        </Button.Group>
                    </Form>
                </Grid.Column>

                <Grid.Column width={ 5 } floated='right' className='tablet only right aligned'>
                    <Form style={ { 'float': 'right' } }>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <Button.Group>
                                    <Link to="/todos/new-todo">
                                        <Button animated='vertical'>
                                            <Button.Content hidden>Todo</Button.Content>
                                            <Button.Content visible><Icon name='tasks'/></Button.Content>
                                        </Button>
                                    </Link>
                                    <Button.Or/>
                                    <Link to="/todos/new-habit">
                                        <Button animated='vertical'>
                                            <Button.Content hidden>Habit</Button.Content>
                                            <Button.Content visible><Icon name='calendar alternate'/></Button.Content>
                                        </Button>
                                    </Link>
                                    <Button.Or/>
                                    <Link to="/todos/new-goal">
                                        <Button animated='vertical'>
                                            <Button.Content hidden>Goal</Button.Content>
                                            <Button.Content visible><Icon name='bullseye'/></Button.Content>
                                        </Button>
                                    </Link>
                                </Button.Group>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Grid.Column>

                <Grid.Column width={ 5 } floated='right' className='computer only right aligned'>
                    <Form style={ { 'float': 'right' } }>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <Button.Group>
                                    <Link to="/todos/new-todo">
                                        <Button>
                                            Todo
                                        </Button>
                                    </Link>
                                    <Button.Or/>
                                    <Link to="/todos/new-habit">
                                        <Button>
                                            Habit
                                        </Button>
                                    </Link>
                                    <Button.Or/>
                                    <Link to="/todos/new-goal">
                                        <Button>
                                                Goal
                                        </Button>
                                    </Link>
                                </Button.Group>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Grid.Column>
            </Grid>

            <Grid columns={ 2 } className='mobile only'>
                <Grid.Row>
                    <Grid.Column>
                        <Button.Group>
                            <Link to="/todos/new-todo">
                                <Button animated='vertical'>
                                    <Button.Content hidden>Todo</Button.Content>
                                    <Button.Content visible><Icon name='tasks'/></Button.Content>
                                </Button>
                            </Link>
                            <Button.Or/>
                            <Link to="/todos/new-habit">
                                <Button animated='vertical'>
                                    <Button.Content hidden>Habit</Button.Content>
                                    <Button.Content visible><Icon name='calendar alternate'/></Button.Content>
                                </Button>
                            </Link>
                            <Button.Or/>
                            <Link to="/todos/new-goal">
                                <Button animated='vertical'>
                                    <Button.Content hidden>Goal</Button.Content>
                                    <Button.Content visible><Icon name='bullseye'/></Button.Content>
                                </Button>
                            </Link>
                        </Button.Group>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Form>
                            <Form.Field>
                                { renderTypeSelect() }
                            </Form.Field>
                        </Form>
                    </Grid.Column>
                    <Grid.Column>
                        <Form>
                            <Form.Field>
                                { renderTagSelect() }
                            </Form.Field>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
}

export default TodosHeader;
