import React from 'react';
import { Container } from 'semantic-ui-react';
import Alerts from '../Alerts';
import PropTypes from 'prop-types';

const PageWrapper = (props) => {

    return (
        <div style={ {
            'minHeight': '100vh',
            'backgroundSize': 'cover',
            'backgroundRepeat': 'no-repeat',
            'backgroundPosition': 'center center',
            'backgroundImage': 'url(/welcome-bg2.jpg)',
            'backgroundAttachment': 'fixed'
        } }>
            <Container>
                <h1>HopSkip</h1>
                <Alerts/>
                {props.children}
            </Container>
        </div>
    );
};

PageWrapper.propTypes = {
    children: PropTypes.element
};

export default PageWrapper;
