import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Card, Icon} from 'semantic-ui-react';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';

function ListCard(props) {

    const preferences = useSelector(state => state.user.preferences);

    const { listItem } = props;

    const navigate = useNavigate();

    const [isHovering, setIsHovering] = useState(false);

    const handleEdit = () => {
        navigate('/lists/new-list-item', { state: { listItem: listItem } });
    };

    if (preferences === null) return;

    const cardTitleHex = preferences.cardTitleHex ? preferences.cardTitleHex : '#546e7a';
    const cardBackgroundRgba = preferences.cardBackgroundRgba ? preferences.cardBackgroundRgba : 'rgba(255,255,255,1.0)';

    return (
        <Card fluid
            onMouseOver={ () => setIsHovering(true) }
            onMouseOut={ () => setIsHovering(false) }
            style={ { backgroundColor: cardBackgroundRgba } }>
            <Card.Content>
                <Card.Header style={ { color: cardTitleHex } }>
                    { isHovering &&
                        <div style={ { float: 'right', marginRight: '-1rem', marginTop: '-.5rem' } }>
                            <Icon circular color='grey' link name='pencil alternate' onClick={ handleEdit }/>
                        </div>
                    }
                    { listItem.name }

                </Card.Header>
            </Card.Content>
        </Card>
    );
}

ListCard.propTypes = {
    listItem: PropTypes.object
};

export default ListCard;
