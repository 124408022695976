import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Header } from 'semantic-ui-react';
import Todo from '../todo/Todo';
import { CardTypes, orderedItemsOfType } from '../../utils/todoUtils';

import styles from '../todo/Todos.module.css';
import { getDaysAheadDayString, getTodayDayString } from '../../utils/dateUtils';

function TodosTopPriority() {

    const map = useSelector((state) => state.todos.map);
    const sortOrder = useSelector((state) => state?.todos?.sortOrder?.order ?? []);
    const todos = useSelector((state) => state.todos.list ? state.todos.list : null);
    useSelector((state) => state.tags.list ? state.tags.list : null);

    const DAYS_TO_DISPLAY = 5;

    const visibleCardsOfType = (type, sortOrder) => {
        const startString = getTodayDayString();
        const endString = getDaysAheadDayString(DAYS_TO_DISPLAY);
        return orderedItemsOfType(todos, type, map, sortOrder)
            .filter(item => item.dueDate && item.dueDate >= startString && item.dueDate < endString);
    };

    const showCardsOfType = (type) => {
        return visibleCardsOfType(type, sortOrder).length > 0;
    };

    const renderCardSectionOfType = (type) => {
        return (
            <React.Fragment key={type}>
                { renderCardSectionForDevice(type, 4, 'computer only')}
                { renderCardSectionForDevice(type, 3, 'tablet only')}
                { renderCardSectionForDevice(type, 1, 'mobile only')}
            </React.Fragment>
        );
    };

    const renderCardSectionForDevice = (type, columns, device) => {
        if (!showCardsOfType(type)) return;

        return (
            <>
                <Grid columns={ columns } className={ device }>
                    { renderCardSectionHeader() }
                </Grid>
                <Grid columns={ columns } className={ device }>
                    { renderCardOfType(type) }
                </Grid>
            </>
        );
    };

    const renderCardSectionHeader = () => {
        return (
            <Grid.Column>
                <div className={ styles.todosSectionTitle }>
                    <Header as='h3' className={styles.todoSectionHeaderLeft}>
                        Due In Next { DAYS_TO_DISPLAY } Days
                    </Header>
                </div>
            </Grid.Column>);
    };

    const renderCardOfType = (type) => {
        return visibleCardsOfType(type, sortOrder)
            .map((todo) => {
                return (
                    <Grid.Column key={ todo.id }>
                        <Todo todo={ todo }/>
                    </Grid.Column>
                );
            });
    };

    return (
        <div>
            { renderCardSectionOfType(CardTypes.todo)}
        </div>
    );
}

export default TodosTopPriority;
