import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    REFRESH_TOKEN_SUCCESS, COMPLETE_REGISTRATION_SUCCESS, COMPLETE_REGISTRATION_FAIL
} from '../actions';

const user = JSON.parse(localStorage.getItem('user'));

const initialState = user
    ? { isLoggedIn: true, user }
    : { isLoggedIn: false, user: null };

function authReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
    case REGISTER_SUCCESS:
        return { ...state, isLoggedIn: false, };
    case REGISTER_FAIL:
        return { ...state, isLoggedIn: false, };
    case COMPLETE_REGISTRATION_SUCCESS:
        return { ...state, isLoggedIn: false, };
    case COMPLETE_REGISTRATION_FAIL:
        return { ...state, isLoggedIn: false, };
    case LOGIN_SUCCESS:
        return { ...state, isLoggedIn: true, user: payload.user, };
    case LOGIN_FAIL:
        return { ...state, isLoggedIn: false, user: null, };
    case LOGOUT_SUCCESS:
        return { ...state, isLoggedIn: false, user: null, };
    case REFRESH_TOKEN_SUCCESS:
        return { ...state, user: {...state.user, accessToken: payload }, };
    default:
        return state;
    }
}

export default authReducer;
