import 'regenerator-runtime/runtime';
import { fork } from 'redux-saga/effects';
import {
    watchCompleteTodo,
    watchCreateTodo,
    watchDeleteTodo,
    watchElectFailTodo,
    watchFetchTodo,
    watchFetchTodos,
    watchIncrementCountTodo,
    watchDecrementCountTodo,
    watchSetCountTodo,
    watchFetchSortOrder,
    watchSaveSortOrder, watchSetGoalProgressTodo, watchUpdateTodo
} from './todosSaga';
import { watchCreateTag, watchDeleteTag, watchFetchTags } from './tagsSaga';
import { watchSuccessAlert, watchErrorAlert } from './alertsSaga';
import { watchAppMetrics } from './metricsSaga';
import {
    watchChangePassword,
    watchCompleteRegistration,
    watchLogin,
    watchLogout, watchPasswordReset,
    watchRegister,
    watchStartPasswordReset
} from './authSaga';
import {
    watchFetchCompletedInsights,
    watchFetchHabitInsights,
    watchFetchTodoByDateInsights,
    watchFetchTodoByTagInsights
} from './insightsSaga';
import { watchFetchUserPrefs, watchSaveUserPrefs } from './userPrefsSaga';
import { watchSaveFeedback } from './feedbackSaga';
import {
    watchCreateList,
    watchCreateListItem,
    watchDeleteList, watchDeleteListItem,
    watchFetchList,
    watchFetchLists,
    watchUpdateList, watchUpdateListItem
} from './listsSaga';

export default function* rootSaga() {
    // Todos
    yield fork(watchFetchTodos);
    yield fork(watchFetchTodo);
    yield fork(watchCreateTodo);
    yield fork(watchUpdateTodo);
    yield fork(watchDeleteTodo);
    yield fork(watchCompleteTodo);
    yield fork(watchElectFailTodo);
    yield fork(watchIncrementCountTodo);
    yield fork(watchDecrementCountTodo);
    yield fork(watchSetCountTodo);
    yield fork(watchSetGoalProgressTodo);

    // Lists
    yield fork(watchFetchLists);
    yield fork(watchFetchList);
    yield fork(watchCreateList);
    yield fork(watchUpdateList);
    yield fork(watchDeleteList);
    yield fork(watchCreateListItem);
    yield fork(watchUpdateListItem);
    yield fork(watchDeleteListItem);

    // Tags
    yield fork(watchFetchTags);
    yield fork(watchCreateTag);
    yield fork(watchDeleteTag);

    // Alerts and Metrics
    yield fork(watchSuccessAlert);
    yield fork(watchErrorAlert);
    yield fork(watchAppMetrics);

    // Security and Identity
    yield fork(watchRegister);
    yield fork(watchCompleteRegistration);
    yield fork(watchLogin);
    yield fork(watchLogout);
    yield fork(watchChangePassword);
    yield fork(watchStartPasswordReset);
    yield fork(watchPasswordReset);

    // Insights
    yield fork(watchFetchTodoByDateInsights);
    yield fork(watchFetchTodoByTagInsights);
    yield fork(watchFetchHabitInsights);
    yield fork(watchFetchCompletedInsights);

    // Sort Order
    yield fork(watchFetchSortOrder);
    yield fork(watchSaveSortOrder);

    // User Preferences
    yield fork(watchFetchUserPrefs);
    yield fork(watchSaveUserPrefs);
    yield fork(watchSaveFeedback);
}
