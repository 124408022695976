import 'regenerator-runtime/runtime';
import { takeEvery, put, delay } from 'redux-saga/effects';
import * as actions from '../actions';
import { successAlertSave, successAlertClear, errorAlertSave, errorAlertClear } from '../actions';

export function* watchSuccessAlert() {
    yield takeEvery(actions.SUCCESS_ALERT, successAlertSaga);
}

export function* successAlertSaga(action) {
    yield put(successAlertSave(action.message));
    yield delay(3000);
    yield put(successAlertClear());
}

export function* watchErrorAlert() {
    yield takeEvery(actions.ERROR_ALERT, errorAlertSaga);
}

export function* errorAlertSaga(action) {
    yield put(errorAlertSave(action.message));
    yield delay(3000);
    yield put(errorAlertClear());
}
