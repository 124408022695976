import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Form } from 'semantic-ui-react';
import TodoByDateInsights from './TodoByDateInsights';
import TodoByTagInsights from './TodoByTagInsights';
import tokens from '../../style.module.css';

function TodoInsights() {

    const [chart, setChart] = useState(1);

    const chartOptions = [
        { key: 1, text: 'Completed Over Time', value: 1 },
        { key: 2, text: 'Completed By Tag', value: 2 }
    ];

    const handleChangeChart = (e, { value }) => setChart(value);

    return (

        <div>
            <Form className={tokens.rb1}>
                <Form.Select
                    fluid
                    options={ chartOptions }
                    defaultValue={ chartOptions[0]['value'] }
                    placeholder='Todo Insight Chart'
                    onChange={ handleChangeChart }
                />
            </Form>

            { chart === chartOptions[0].value &&
                <TodoByDateInsights />
            }

            { chart === chartOptions[1].value &&
                <TodoByTagInsights />
            }

        </div>
    );
}

export default TodoInsights;
