import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class Alerts extends Component {
    render() {
        return (
            <div>
                { this.props.loading &&
                    <div></div>
                }

                { this.props.successAlert &&
                    <div className="ui bottom fixed secondary menu">
                        <div className="ui positive message"
                            style={{ width: '100%'}}>
                            { this.props.successAlert }
                        </div>
                    </div>
                }

                { this.props.errorAlert &&
                    <div className="ui bottom fixed secondary menu">
                        <div className="ui negative message"
                            style={{ width: '100%'}}>
                            { this.props.errorAlert }
                        </div>
                    </div>
                }

                { this.props.actionRequiredAlert &&
                    <div className="ui bottom fixed secondary menu">
                        <div className="ui big yellow message"
                            style={{ width: '100%'}}>
                            { this.props.actionRequiredAlert }
                        </div>
                    </div>
                }

            </div>
        );
    }
}

Alerts.propTypes = {
    loading: PropTypes.bool,
    successAlert: PropTypes.string,
    errorAlert: PropTypes.string,
    actionRequiredAlert: PropTypes.string,
};

function mapStateToProps({ alerts }) {
    return {
        successAlert: alerts.successAlert,
        errorAlert: alerts.errorAlert,
        actionRequiredAlert: alerts.actionRequiredAlert,
        loading: alerts.loading
    };
}

export default connect(mapStateToProps)(Alerts);
