import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { createTodo, deleteTodo, updateTodo } from '../../../actions';
import { Button, Form } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import AuthPageWrapper from '../../wrapper/AuthPageWrapper';
import styles from './Form.module.css';
import { isDone } from '../../../utils/todoUtils';
import FormTags from './FormTags';
import DatePicker from 'react-datepicker';
import { getDateFromDayString, getDayString, getTodayDayString } from '../../../utils/dateUtils';

function TodosForm() {

    const [selectedTags, setSelectedTags] = useState([]);
    const [dueDate, setDueDate] = useState(new Date());
    const { register, watch, handleSubmit, reset, formState: { errors } } = useForm();
    const user = useSelector((state) => state?.auth?.user ?? null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const todo = location?.state?.todo;

    useEffect(() => {
        let defaultValues = {};
        defaultValues.tracking = 'true';
        if (user) {
            defaultValues.user = user.username;
        }
        if (todo) {
            defaultValues.id = todo.id;
            defaultValues.description = todo.description;
            defaultValues.details = todo.details;
            defaultValues.tracking = todo.trackingType && todo.trackingType.length > 0;
            setSelectedTags(todo.tags);
            if (todo.dueDate) {
                defaultValues.showDueDate = true;
                setDueDate(getDateFromDayString(todo.dueDate));
            }
        }
        reset({ ...defaultValues });
    }, [user, todo, reset]);

    const onDeleteClick = () => {
        dispatch(deleteTodo(todo, () => {navigate('/todos');}));
    };

    const onSubmit = (data) => {
        data['tags'] = selectedTags;

        if (data['showDueDate'] && dueDate) {
            data['dueDate'] = getDayString(dueDate);
        }

        if (data['tracking']) {
            data['trackingType'] = 'finished';
        } else {
            data['trackingType'] = null;
        }

        if (todo) {
            if (data['done']) {
                data['completedOn'] = [getTodayDayString()];
            } else {
                data['completedOn'] = todo.completedOn;
            }
            dispatch(updateTodo(data, () => {
                navigate('/todos');
            }));
        } else {
            if (data['done']) {
                data['completedOn'] = [getTodayDayString()];
            }
            dispatch(createTodo(data, () => {
                navigate('/todos');
            }));
        }
        //alert(JSON.stringify(data, null, 2))
    };

    return (
        <AuthPageWrapper>
            <Form onSubmit={ handleSubmit(onSubmit) }>
                <Form.Field className={ `${ errors.description ? 'error' : '' }` }>
                    <label htmlFor="description">Description</label>
                    <input autoFocus placeholder="Description" { ...register('description', { required: true }) } />
                    { errors.description &&
                        <div className="ui pointing above prompt label" role="alert" aria-atomic="true">Please enter your
                            last
                            name</div> }
                </Form.Field>
                <Form.Field>
                    <label htmlFor="details">Details</label>
                    <input placeholder="Details" { ...register('details') } />
                </Form.Field>

                <Form.Field className={styles.toggle}>
                    <div className="ui checkbox toggle">
                        <input type="checkbox" id="tracking-id" tabIndex="0" { ...register('tracking') } />
                        <label>Include Finished Button</label>
                    </div>
                </Form.Field>

                <Form.Field className={styles.toggle}>
                    <div className="ui checkbox toggle">
                        <input type="checkbox" id="show-due-date-id" tabIndex="1" { ...register('showDueDate') } />
                        <label>Include a Due Date</label>
                    </div>
                </Form.Field>

                { watch('showDueDate') &&
                    <Form.Field>
                        <DatePicker
                            className={styles.datepicker}
                            selected={dueDate}
                            onChange={(date) => setDueDate(date)} />
                    </Form.Field>
                }

                { (!todo || !isDone(todo)) &&
                    <Form.Field className={styles.toggle}>
                        <div className="ui checkbox toggle">
                            <input type="checkbox" id="done-id" tabIndex="0" { ...register('done') } />
                            <label>Todo is Done</label>
                        </div>
                    </Form.Field>
                }

                <FormTags tags={todo ? todo.tags : []} setSelectedTags={setSelectedTags}/>

                {todo &&
                    <Button type="button"
                        className="negative"
                        onClick={onDeleteClick}>
                        Delete Todo
                    </Button>
                }

                <Button type="button"
                    className="secondary"
                    onClick={ (e) => {
                        e.preventDefault();
                        navigate('/todos');
                    } }>
                    Cancel
                </Button>
                <Button className="primary" type="submit">Submit</Button>
            </Form>
        </AuthPageWrapper>
    );

}

export default TodosForm;
