import {
    DELETE_TAG_RESPONSE,
    RESET_STATE,
    SET_SELECTED_LIST,
    SET_SELECTED_LIST_VIEW,
    SET_TODO_TAG_FILTERS,
    SET_TODO_TYPE_FILTERS,
    SET_TODO_VIEW
} from '../actions';

function uxReducer(state = {todoTypeFilters: [], todoTagFilters: [], todoView: 'rows'}, action) {
    switch (action.type) {
    case SET_TODO_TYPE_FILTERS:
        return { ...state, todoTypeFilters: action.filters };
    case SET_TODO_TAG_FILTERS:
        return {...state, todoTagFilters: action.filters };
    case SET_TODO_VIEW:
        return {...state, todoView: action.view};
    case SET_SELECTED_LIST:
        return {...state, listSelectedList: action.list};
    case SET_SELECTED_LIST_VIEW:
        return {...state, listSelectedView: action.view};
    case DELETE_TAG_RESPONSE:
        return {...state, todoTagFilters: []};
    case RESET_STATE:
        return {todoTypeFilters: [], todoTagFilters: []};
    default:
        return state;
    }
}

export default uxReducer;
